import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import Caption from 'components/Luxkit/Typography/Caption'
import Group from 'components/utils/Group'
import React, { useCallback, useContext, useRef } from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import ContactContext from 'contexts/contactContext'
import useToggle from 'hooks/useToggle'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import NavBarContactsItems from './NavBarContactsItems'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import ModalContext from 'contexts/ModalContext'
import Modal from 'components/Luxkit/Modal/Modal'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import GeoContext from 'contexts/geoContext'
import useOrders from 'hooks/Order/useOrders'
import { useAppSelector } from 'hooks/reduxHooks'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'

interface Props {
  className?: string;
  /**
   * The minimum breakpoint when the text disappears
   */
  textBreakpoint?: App.ScreenSize;
}

function NavBarContactsButton(props: Props) {
  const { className, textBreakpoint } = props
  const [open, toggleOpen, _, closeDropdown] = useToggle()

  const { currentRegionCode } = useContext(GeoContext)
  const chatEnabled = useAppSelector(isSupportAssistantEnabled)
  const [upcomingOrders] = useOrders('upcoming')
  const isMobile = useScreenSizeOnly('mobile')
  const showModal = useContext(ModalContext)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const contact = useContext(ContactContext)

  const onNavClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (isMobile) {
      showModal(resolve => <Modal title="Contact us 24/7">
        <NavBarContactsItems onItemClick={resolve} />
      </Modal>)
    } else {
      toggleOpen()
    }
  }, [isMobile, showModal, toggleOpen])

  const has247Support = !NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode)

  if (upcomingOrders.length && chatEnabled) {
    // Push the user to use our chat if they have an upcoming order instead of calling us
    return null
  }

  return <>
    <Group direction="horizontal" gap={4} verticalAlign="center" className={className}>
      <CSSBreakpoint min={textBreakpoint}>
        <Caption variant="medium" colour="neutral-three" wrap="no-wrap">
          {!has247Support && 'Contact us 7 days'}
          {has247Support && <>
            <CSSBreakpoint as="span" max="tablet">Contact us 24/7</CSSBreakpoint>
            <CSSBreakpoint as="span" min="desktop">Contact us 24 hours, 7 days</CSSBreakpoint>
          </>}
        </Caption>
      </CSSBreakpoint>
      <NavBarButton
        size="small"
        icon={<LinePhoneIcon />}
        dropdown
        ref={buttonRef}
        onClick={onNavClick}
      >
        {contact.defaultContact.humanReadable}
      </NavBarButton>
    </Group>
    <DropdownList
      size="S"
      open={!isMobile && open}
      onClose={closeDropdown}
      anchorRef={buttonRef}
      triggerRef={buttonRef}
    >
      <NavBarContactsItems />
    </DropdownList>
  </>
}

export default React.memo(NavBarContactsButton)
