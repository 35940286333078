import React from 'react'
import SvgIcon from 'components/Luxkit/SvgIcon'

interface Props extends Omit<React.ComponentProps<typeof SvgIcon>, 'size'> {
  Icon: typeof SvgIcon;
}

function NavBarListItemIcon(props: Props) {
  const { Icon, ...rest } = props
  return <Icon size="S" {...rest} />
}

export default NavBarListItemIcon
