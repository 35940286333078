import { createSelector } from 'reselect'
import getLuxLoyaltyDisplayTier from './getLuxLoyaltyDisplayTier'
import determineNextLuxLoyaltyTier from 'luxLoyalty/lib/determineNextLuxLoyaltyTier'

const getLuxLoyaltyNextTier = createSelector(
  (state: App.State) => getLuxLoyaltyDisplayTier(state),
  (displayTier): App.LuxLoyaltyTier | undefined => {
    return determineNextLuxLoyaltyTier(displayTier)
  },
)

export default getLuxLoyaltyNextTier
