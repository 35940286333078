import FormatCurrency from 'components/Common/FormatCurrency'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import withMountGuard from 'hocs/withMountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxPlusLogo from 'luxPlus/components/Logos/LuxPlusLogo'
import { LUXURY_PLUS, LUXURY_PLUS_PATHS } from 'luxPlus/constants/base'
import { checkCanRedeemLuxPlusBenefits, isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import getLuxPlusTotalValueSaved from 'luxPlus/selectors/benefits/getLuxPlusTotalValueSaved'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import IconButton from 'components/Luxkit/Button/IconButton'

const Container = styled.div`
  background-color: ${props => props.theme.palette.product.luxPlus.background};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  padding: ${rem(16)} ${rem(8)} ${rem(16)} ${rem(16)};
  border-radius: ${props => props.theme.borderRadius.M};
`

const SubContainer = styled.div`
  padding: ${rem(12)} ${rem(16)};
`

const FadedCaption = styled(Caption)`
  opacity: 0.7;
`

const AccountSnapshotContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
`

const FadedDivider = styled(Divider)`
  opacity: 0.3;
`

const FadedLoadingBox = styled(TextLoadingBox)`
  opacity: 0.3;
`

interface Props {
  onLinkClick: () => void
}

function AccountMenuLuxPlusDetailsTile({ onLinkClick }: Props) {
  const totalValueSaved = useAppSelector(getLuxPlusTotalValueSaved)
  const luxPlusSubscription = useAppSelector(state => state.auth.account.luxPlus.member.subscription)
  const isSubscribedLuxPlusMember = useAppSelector(checkCanRedeemLuxPlusBenefits)

  if (luxPlusSubscription.fetching) {
    return <Container>
      <Group direction="vertical" gap={16}>
        <div>
          <LuxPlusLogo colour="inherit" height={16} />
        </div>
        <SubContainer>
          <FadedLoadingBox width="100%" lines={1} typography="heading2" />
        </SubContainer>
      </Group>
    </Container>
  }

  if (!isSubscribedLuxPlusMember || luxPlusSubscription.error) return null

  return <Container>
    <Group direction="vertical" gap={8}>
      <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
        <LuxPlusLogo colour="inherit" height={12} />
        <IconButton
          kind="tertiary"
          variant="ghost"
          horizontalOutdent="end"
          size="small"
          to={`/${LUXURY_PLUS_PATHS.ACCOUNT_MANAGEMENT_PAGE}`}
          onClick={onLinkClick}
        >
          <LineAngleRightIcon />
        </IconButton>
      </Group>
      <SubContainer>
        {!totalValueSaved && <FadedCaption variant="medium" align="center">
          Welcome to {LUXURY_PLUS.PROGRAM_NAME} where you’ll save even more
        </FadedCaption>}
        {!!totalValueSaved && <AccountSnapshotContainer>
          <Group direction="vertical" gap={2} horizontalAlign="center">
            <FadedCaption variant="medium">
              Savings
            </FadedCaption>
            <Heading variant="heading6" colour="neutral-eight">
              <FormatCurrency value={totalValueSaved} format="roundedDollar" />
            </Heading>
          </Group>
          <div>
            <FadedDivider kind="primary" direction="vertical" />
          </div>
          <Group direction="vertical" gap={2} horizontalAlign="center">
            <FadedCaption variant="medium">
              Join date
            </FadedCaption>
            <Heading variant="heading6" colour="neutral-eight">
              <FormatDateTime value={luxPlusSubscription.item!.memberSince} year="2-digit" month="short" day="2-digit" />
            </Heading>
          </Group>
        </AccountSnapshotContainer>}
      </SubContainer>
    </Group>
  </Container>
}

export default withMountGuard(() => {
  return useAppSelector(isLuxPlusEnabled)
})(AccountMenuLuxPlusDetailsTile)
