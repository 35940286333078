import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineSignoutIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M4 12a1 1 0 0 0 1 1h7.59l-2.3 2.29a.9997.9997 0 0 0 0 1.42.9997.9997 0 0 0 1.42 0l4-4a1.0002 1.0002 0 0 0 .21-.33c.1-.2435.1-.5165 0-.76a1.0002 1.0002 0 0 0-.21-.33l-4-4a1.003 1.003 0 0 0-.71-.294 1.0045 1.0045 0 0 0-.9277.6197 1.0052 1.0052 0 0 0 0 .7685c.0505.1219.1245.2326.2177.3258l2.3 2.29H5a1.0002 1.0002 0 0 0-1 1ZM17 2H7a3 3 0 0 0-3 3v3a1 1 0 0 0 2 0V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1.0001 1.0001 0 0 1-1 1H7a1.0002 1.0002 0 0 1-1-1v-3a1 1 0 1 0-2 0v3a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3Z"/>
  </SvgIcon>
}

export default LineSignoutIcon
