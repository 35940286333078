import cn from 'clsx'
import Badge from 'components/Luxkit/Badge'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import { setSvgIconSize } from 'components/Luxkit/SvgIcon'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React from 'react'
import { hasAccountNotification } from 'selectors/accountSelectors'
import styled from 'styled-components'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { getHasLuxLoyaltyAccount } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { LargeButtonTypography } from 'components/Luxkit/Typography/ButtonTypography'
import useCurrentLoyaltyTier from 'luxLoyalty/hooks/useCurrentLoyaltyTier'

const IconContainer = styled.div`
  position: relative;
`

const LuxPlusRingContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LuxPlusRing = styled.div`
  position: absolute;
  height: calc(100% + 8px);
  width: calc(100% + 8px);
  border-radius: ${props => props.theme.borderRadius.round};
  border: 2px solid ${props => props.theme.palette.product.luxPlus.background};
`

const NotificationBadge = styled(Badge)`
  position: absolute;
  top: calc(50% - 2px);
  transform: translateY(-100%) translateX(50%);
  right: 0;
`

const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${rem(24)};
  width: ${rem(24)};
  border-radius: ${props => props.theme.borderRadius.round};
  background-color: ${props => props.theme.palette.neutral.default.one};
  text-transform: uppercase;
  ${setSvgIconSize(16)}
  ${LargeButtonTypography}
  font-weight: ${props => props.theme.font.secondary.weight.bold};
  color: ${props => props.theme.palette.neutral.default.eight};

  ${mediaQueryUp.tablet} {
    ${setSvgIconSize(16)}
    height: ${rem(32)};
    width: ${rem(32)};
  }

  &.lux-plus-member {
    background-color: ${props => props.theme.palette.product.luxPlus.background};
  }

  &.lux-loyalty-tier-bronze {
    background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkContrast};
  }

  &.lux-loyalty-tier-silver {
    background-color: ${props => props.theme.palette.product.luxLoyalty.silver.darkBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.darkContrast};
  }

  &.lux-loyalty-tier-gold {
    background-color: ${props => props.theme.palette.product.luxLoyalty.gold.darkBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.darkContrast};
  }

  &.lux-loyalty-tier-platinum {
    background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.darkBackground};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.darkContrast};
  }
`

function NavBarUserAvatar() {
  const account = useAppSelector(state => state.auth.account)
  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))
  const isLuxLoyaltyMember = useAppSelector(state => getHasLuxLoyaltyAccount(state))
  const tier = useCurrentLoyaltyTier()
  const isLuxPlusAndLuxLoyaltyMember = isLuxPlusMember && isLuxLoyaltyMember
  const showNotification = useAppSelector(hasAccountNotification)

  const oneInitial = account.givenName?.[0]

  return <IconContainer>
    <LuxPlusRingContainer>
      {isLuxPlusAndLuxLoyaltyMember && <LuxPlusRing />}
      <Avatar className={cn(
        { 'lux-plus-member': isLuxPlusMember },
        { [`lux-loyalty-tier-${tier}`]: isLuxLoyaltyMember },
        { 'lux-plus-and-lux-loyalty-member': isLuxPlusAndLuxLoyaltyMember },
      )}
      >
        {oneInitial}
        {!oneInitial && <LineUserIcon />}
      </Avatar>
    </LuxPlusRingContainer>
    {showNotification && <NotificationBadge
      mode="critical"
      kind="dot"
    />}
  </IconContainer>
}

export default React.memo(NavBarUserAvatar)
