import { rem } from 'polished'
import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(8)};
`

function NavBarList(props: PropsWithChildren) {
  return <Container {...props} />
}

export default NavBarList
