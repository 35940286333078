import React, { forwardRef, useCallback } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import Clickable from 'components/Common/Clickable'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { mediaQueryOnly, mediaQueryUp } from 'components/utils/breakpoint'
import Group from 'components/utils/Group'
import { getNavigationMenuIcon } from 'content/components/Icon/utils'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const NewBadge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  padding: 0 ${rem(2)};
  border-radius: ${props => props.theme.borderRadius.S};
  font-weight: ${props => props.theme.font.primary.weight.bold};
  /* This has not been designed to match luxkit yet, and needs specific values */
  font-size: 10px;
  line-height: 13px;
`

const NavLink = styled(Clickable)`
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  height: ${rem(56)};
  padding: 0 ${rem(12)};
  background-color: transparent;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  box-shadow: inset 0 0 0 ${props => props.theme.palette.brand.primary.normal},
              0 0 0 ${props => props.theme.palette.brand.primary.normal};

  &.active {
    box-shadow: inset 0 -3px 0 ${props => props.theme.palette.brand.primary.normal},
                0 1px 0 ${props => props.theme.palette.brand.primary.normal};
  }

  ${mediaHoverable} {
    &:hover {
      background-color: ${props => props.theme.palette.neutral.default.seven};
    }
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }

  &.mobile-nav {
    display: flex;
  }

  ${mediaQueryUp.largeDesktop} {
    display: flex;
  }

  ${mediaQueryOnly.tablet} {
    &.hide-tablet {
      display: none;
    }
  }

`
const Chevron = styled(DropdownChevron)`
  margin-left: ${rem(8)};
`

interface Props extends Omit<React.HTMLAttributes<HTMLElement>, 'onClick'> {
  navItem: App.NavItem;
  onClick?: (navItem: App.NavItem, position: number) => void;
  open?: boolean;
  active?: boolean;
  position: number;
}

const NavigationNavItem = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, active, navItem, onClick, open, position, ...rest } = props
  const Icon = navItem.iconName && getNavigationMenuIcon(navItem.iconName)

  const onNavClick = useCallback(() => {
    onClick?.(navItem, position)
  }, [navItem, onClick, position])

  return (
    <NavLink
      {...rest}
      ref={ref}
      role="menuitem"
      className={cn(
        className,
        navItem.linkClass,
        {
          active,
          'mobile-nav': navItem.mobileNav,
          'hide-tablet': navItem.hideOnTablet,
        },
      )}
      to={navItem.to}
      target={navItem.target}
      href={navItem.href}
      onClick={onNavClick}
    >
      <Group direction="horizontal" gap={8} verticalAlign="center">
        {Icon && <Icon size="S" />}
        <BodyText
          weight="bold"
          wrap="no-wrap"
          variant="medium"
          colour="neutral-one"
        >
          {navItem.text}
        </BodyText>
      </Group>
      {!!navItem.items?.length && <Chevron open={open} />}
      {navItem.newLabel && <NewBadge>NEW</NewBadge>}
    </NavLink>
  )
})

export default NavigationNavItem
