import cn from 'clsx'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Group from 'components/utils/Group'
import NavBarLoyaltyLogo from './NavBar/NavBarLoyaltyLogo'
import NavBarProducts from './NavBar/NavBarProducts'
import { useAppSelector } from 'hooks/reduxHooks'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import useIsOfferPage from 'hooks/Navigation/useIsOfferPage'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import useIsSearchPage from 'hooks/Navigation/useIsSearchPage'
import useIsHomepage from 'home/hooks/isHomepage'

const BarPane = styled(Pane)`
  width: 100%;
  display: none;
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};

  &.show {
    display: block;
  }

  &:not(.hide) {
    &:not(.desktop-up) {
      ${mediaQueryUp.tablet} {
        display: block;
      }
    }

    &.desktop-up {
      ${mediaQueryUp.desktop} {
        display: block;
      }
    }
  }

`

const GlobalBar = styled(LayoutContainer)`
  height: ${rem(48)};
  padding: 0 ${rem(8)} 0 ${rem(4)};
  display: flex;
  justify-content: space-between;
  vertical-align: center;

  ${mediaQueryUp.desktop} {
    padding: 0 ${rem(16)};
  }

  ${mediaQueryUp.largeDesktop} {
    padding: 0 ${rem(20)};
  }
`

function NavBarGlobalBar() {
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const loyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)

  const isOfferPage = useIsOfferPage()
  const isSearchPage = useIsSearchPage()
  const isHomepage = useIsHomepage()

  return <BarPane
    className={cn({
      // Homepage always shows the bar
      show: isHomepage,
      // Global bar isn't shown on offer pages
      hide: isOfferPage,
      // Search shows it's own bar until desktop
      'desktop-up': isSearchPage,
    })}
  >
    <HorizontalContainerWithFadedOverflow>
      <GlobalBar variant="extraLarge">
        <NavBarProducts />
        <Group direction="horizontal" gap={16} verticalAlign="center">
          {luxPlusEnabled && <NavBarLoyaltyLogo variant="luxPlus" />}
          {loyaltyEnabled && <NavBarLoyaltyLogo variant="loyalty" />}
        </Group>
      </GlobalBar>
    </HorizontalContainerWithFadedOverflow>
  </BarPane>
}

export default NavBarGlobalBar
