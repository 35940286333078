import HeaderNotificationItem from 'components/App/Header/HeaderMain/HeaderNotificationItem'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import useUserNotifications from 'hooks/Notifications/useUserNotifications'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const EmptyState = styled(Group)`
  padding: ${rem(64)} ${rem(20)};
`

function NarBarNotificationsContent() {
  const [notifications] = useUserNotifications()

  const isSubscribed = useAppSelector(state => state.leSubscriptions.settings.app_push_subscribed)

  if (!notifications.length) {
    return <EmptyState direction="vertical" gap={8} horizontalAlign="center">
      <LineBellIcon />
      <Group direction="vertical" horizontalAlign="center">
        <BodyText variant="large" weight="bold">No new notifications</BodyText>
        {isSubscribed && <BodyText variant="medium">We’ll let you know when updates arrive.</BodyText>}
      </Group>
    </EmptyState>
  }

  return <>
    {notifications.map(notification => <HeaderNotificationItem
      key={notification.id}
      notification={notification}
    />)}
  </>
}

export default React.memo(NarBarNotificationsContent)
