import cn from 'clsx'
import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import NavBarBrandLogo from './NavBar/NavBarBrandLogo'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import NavBarNotificationsButton from './NavBarButtons/NavBarNotificationsButton'
import NavBarUserButton from './NavBarButtons/NavBarUserButton'
import NavBarRegionButton from './NavBarButtons/NavBarRegionButton'
import NavBarSearchInput from './NavBar/NavBarSearchInput'
import NavBarDrawerButton from './NavBarButtons/NavBarDrawerButton'
import NavBarTripButton from './NavBarButtons/NavBarTripButton'
import NavBarLoginButton from './NavBarButtons/NavBarLoginButton'
import NavBarCartButton from './NavBarButtons/NavBarCartButton'
import NavBarSearchButton from './NavBarButtons/NavBarSearchButton'
import NavBarMyEscapesButton from './NavBarButtons/NavBarMyEscapesButton'
import useIsHomepage from 'home/hooks/isHomepage'
import useIsSearchPage from 'hooks/Navigation/useIsSearchPage'

const BarPane = styled(Pane)`
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};

  &.mobile-borderless {
    border-bottom: none;

    ${mediaQueryUp.tablet} {
      border-bottom: 1px solid ${props => props.theme.palette.neutral.default.six};
    }
  }
`

const UtilBar = styled(LayoutContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${rem(64)};
  padding-left: ${rem(4)};
  padding-right: ${rem(16)};

  &.logged-in {
    padding-right: ${rem(8)};
  }

  ${mediaQueryUp.tablet} {
    display: grid;
    grid-template-columns: 1fr minmax(1px, ${rem(1140)}) 1fr;
    gap: ${rem(8)};

    &.logged-in {
      padding-right: ${rem(12)};
    }
  }

  ${mediaQueryUp.desktop} {
    gap: ${rem(32)};
  }

  ${mediaQueryUp.largeDesktop} {
    padding-right: ${rem(32)};
    padding-left: ${rem(20)};
    gap: ${rem(64)};

    &.logged-in {
      padding-right: ${rem(20)};
    }
  }
`

const UtilSearchBar = styled(LayoutContainer)`
  padding: 0 ${rem(16)};

  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const UtilBarStart = styled.div`
  display: flex;
  align-items: center;
  gap: ${rem(4)};

  ${mediaQueryUp.desktop} {
    gap: ${rem(8)};
    flex-grow: 1;
  }
`

const UtilBarEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${rem(8)};
`

const NavBarUtilBarSearchInput = styled(NavBarSearchInput)`
 display: none;

 ${mediaQueryUp.tablet} {
    display: flex;
  }
`

const NavBarUtilRegionButton = styled(NavBarRegionButton)`
  display: none;

  ${mediaQueryUp.tablet} {
    display: flex;
  }
`

const NavBarUtilMyEscapes = styled(NavBarMyEscapesButton)`
  display: none;

  ${mediaQueryUp.extraLargeDesktop} {
    display: flex;
  }
`

const NavBarUtilLoginButton = styled(NavBarLoginButton)`
  /** Login button requires extra spacing gap alone cannot provide */
   margin-left: ${rem(8)};
`

function NavBarUtilBar() {
  const loggedIn = useAppSelector(selectLoggedIn)
  // Homepage is special and shows a few different things like a search bar below the util bar
  const isHomepage = useIsHomepage()
  const isSearchPage = useIsSearchPage()

  return <BarPane className={cn({ 'mobile-borderless': isHomepage || isSearchPage }) }>
    <UtilBar
      variant="extraLarge"
      className={cn({ 'logged-in': loggedIn })}
    >
      <UtilBarStart>
        <NavBarDrawerButton />
        <NavBarBrandLogo />
      </UtilBarStart>
      <div>
        <NavBarUtilBarSearchInput />
      </div>
      <UtilBarEnd>
        <NavBarSearchButton />
        <NavBarUtilRegionButton />
        <NavBarTripButton />
        {!loggedIn && <NavBarUtilLoginButton />}
        {loggedIn && <>
          <NavBarNotificationsButton />
          <NavBarCartButton />
          <NavBarUtilMyEscapes />
          <NavBarUserButton />
        </>}
      </UtilBarEnd>
    </UtilBar>
    {isHomepage && <UtilSearchBar variant="extraLarge">
      <NavBarSearchInput />
    </UtilSearchBar>}
  </BarPane>
}

export default NavBarUtilBar
