import LuxLoyaltyTierLabel from 'components/Common/Labels/LuxLoyaltyTierLabel'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import Group from 'components/utils/Group'
import React from 'react'
interface Props {
  benefit: App.LuxLoyaltyBenefit;
  tier: App.LuxLoyaltyTier;
  title: string;
  icon: React.ReactElement;
}

function AccountDrawerBenefitHighlightItem(props: Props) {
  const { benefit, tier, title, icon } = props

  return (
    <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="center">
      <BodyTextBlock variant="small" startIcon={icon}>
        {title}
      </BodyTextBlock>
      <LuxLoyaltyTierLabel kind="tertiary" tier={tier}>
        {benefit.unlimited && 'Unlimited'}
        {!benefit.unlimited && `${benefit.remaining === 0 ? 'Redeemed' : `${benefit.remaining}x`}`}
      </LuxLoyaltyTierLabel>
    </Group>
  )
}

export default AccountDrawerBenefitHighlightItem
