import LineBarsIcon from 'components/Luxkit/Icons/line/LineBarsIcon'
import React, { useCallback, useContext } from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import ModalContext from 'contexts/ModalContext'
import NavBarHamburgerMenu from '../NavBarHamburgerMenu/NavBarHamburgerMenu'

interface Props {
  className?: string;
}

function NavBarDrawerButton(props: Props) {
  const showModal = useContext(ModalContext)

  const onDrawerClick = useCallback(() => {
    showModal(<NavBarHamburgerMenu />)
  }, [showModal])

  return <NavBarButton
    {...props}
    icon={<LineBarsIcon />}
    onClick={onDrawerClick}
  />
}

export default React.memo(NavBarDrawerButton)
