import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import useCurrentGlobalSearchVerticalPage from 'hooks/GlobalSearch/useCurrentGlobalSearchVerticalPage'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import useIsSearchPage from 'hooks/Navigation/useIsSearchPage'
import Clickable from 'components/Common/Clickable'
import { rem } from 'polished'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import BodyText from 'components/Luxkit/Typography/BodyText'

const FullSearchInput = styled(Clickable)`
  display: flex;
  border: 1px solid transparent;
  background-color: ${props => props.theme.palette.neutral.default.seven};
  border-radius: ${props => props.theme.borderRadius.S};
  container-type: size;
  align-items: center;
  gap: ${rem(8)};
  height: ${rem(40)};
  padding: ${rem(8)} ${rem(16)};
  transition: border-color 0.2s, opacity 0.2s;
  gap: ${rem(12)};
  width: 100%;
  opacity: 1;

  &[hidden] {
    pointer-events: none;
    opacity: 0;
  }

  ${mediaHoverable} {
    &:hover {
      border-color: ${props => props.theme.palette.neutral.default.five};
    }
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px) solid ${props => props.theme.palette.neutral.default.five};
    outline-offset: 1px;
  }
`

const InputText = styled(BodyText)`
  @container (width >= 260px) {
    .short {
      display: none;
    }
  }

  @container (width < 260px) {
    .long {
      display: none;
    }
  }

`

interface Props {
  className?: string;
}

function NavBarSearchInput(props: Props) {
  const { className } = props
  const globalSearchVerticalTabs = useGlobalSearchVerticalTabs()
  const currentSearchVerticalPage = useCurrentGlobalSearchVerticalPage()
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()

  const isSearchPage = useIsSearchPage()

  const onSearchClick = useCallback(() => {
    const verticalTab = currentSearchVerticalPage ?? globalSearchVerticalTabs.keys().next().value
    toggleSearchVertical(verticalTab)
  }, [currentSearchVerticalPage, globalSearchVerticalTabs, toggleSearchVertical])

  return <FullSearchInput
    className={className}
    onClick={onSearchClick}
    hidden={isSearchPage}
    aria-hidden={isSearchPage}
  >
    <LineSearchIcon size="S" />
    <InputText variant="medium" colour="primary-dark" wrap="truncate" >
      <span className="long">Search our exclusive travel offers</span>
      <span className="short">Search offers</span>
    </InputText>
  </FullSearchInput>
}

export default React.memo(NavBarSearchInput)
