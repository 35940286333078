import FormatCurrency from 'components/Common/FormatCurrency'
import LineCreditCardIcon from 'components/Luxkit/Icons/line/LineCreditCardIcon'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import { useAppSelector } from 'hooks/reduxHooks'
import React, { ComponentProps } from 'react'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import NavBarListItemIcon from '../NavBarListItemIcon'

type InheritedListItemProps = Pick<ComponentProps<typeof NavBarListItem>, 'onClick'>

function AccountMenuCreditListItem(props: InheritedListItemProps) {
  const balance = useAppSelector(state => state.auth.account.balance)
  const balanceFetched = useAppSelector(state => state.auth.account.balanceFetched)
  const balanceError = useAppSelector(state => state.auth.account.balanceError)

  return <NavBarListItem
    {...props}
    startIcon={<NavBarListItemIcon Icon={LineCreditCardIcon} />}
    title="Credits & Payment methods"
    subtitle={!balanceError && (!balanceFetched || balanceFetched && balance > 0) && <>
      {balance && <><FormatCurrency value={balance} /> credits available</>}
      {!balanceFetched && <TextLoadingBox width="6ch" />}
    </>}
    to="/account/credits-payments"
  />
}

export default AccountMenuCreditListItem
