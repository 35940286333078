import React from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props extends React.PropsWithChildren {
  titleFormat?: React.ComponentProps<typeof BodyText>['format']
  titleLineClamp?: React.ComponentProps<typeof BodyText>['lineClamp']
  titleColour?: React.ComponentProps<typeof BodyText>['colour']
}

function NavBarListItemTitle(props: Props) {
  const { titleFormat, titleLineClamp, titleColour, children } = props
  return <BodyText
    variant="medium"
    format={titleFormat}
    lineClamp={titleLineClamp}
    colour={titleColour}
  >
    {children}
  </BodyText>
}

export default NavBarListItemTitle
