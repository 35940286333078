import { mediaQueryOnly } from 'components/utils/breakpoint'
import { useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback, useContext, useRef } from 'react'
import { getFlatNavItems } from 'selectors/navigationSelectors'
import styled from 'styled-components'
import NavBarTab from '../NavBarTab/NavBarTab'
import Group from 'components/utils/Group'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import ListItem from 'components/Luxkit/List/ListItem'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import ModalContext from 'contexts/ModalContext'
import Modal from 'components/Luxkit/Modal/Modal'

const MoreNavTab = styled(NavBarTab)`
  display: none;
`

/**
 * We hide products via pure CSS. This is done to ensure 100% SSR matching behaviour
 * in both the tabs and the dropdown.
 *
 * The following breakpoints allow these number of items:
 * Mobile -> 4
 * Tablet -> 4
 * Desktop -> 5
 * Large desktop -> 8
 * Extra lareg desktop -> 10
 *
 * We then show the 'more' button if there would be items after this
 */
const ProductTabs = styled(Group)`
  ${mediaQueryOnly.mobile} {
    & >:nth-child(n + 5) {
      display: none;

      &${MoreNavTab}:nth-child(n + 6) {
        display: flex;
      }
    }
  }

  ${mediaQueryOnly.tablet} {
    & >:nth-child(n + 5) {
      display: none;

      &${MoreNavTab}:nth-child(n + 6) {
        display: flex;
      }
    }
  }

  ${mediaQueryOnly.desktop} {
    & >:nth-child(n + 6) {
      display: none;

      &${MoreNavTab}:nth-child(n + 7) {
        display: flex;
      }
    }
  }

  ${mediaQueryOnly.largeDesktop} {
    & >:nth-child(n + 9) {
      display: none;

      &${MoreNavTab}:nth-child(n + 10) {
        display: flex;
      }
    }
  }

  ${mediaQueryOnly.extraLargeDesktop} {
    & >:nth-child(n + 11) {
      display: none;

      &${MoreNavTab}:nth-child(n + 12) {
        display: flex;
      }
    }
  }
`

const NavListItem = styled(ListItem)`
  ${mediaQueryOnly.mobile} {
    &:not(:nth-child(n + 5)) {
      display: none;
    }
  }

  ${mediaQueryOnly.tablet} {
    &:not(:nth-child(n + 5)) {
      display: none;
    }
  }

  ${mediaQueryOnly.desktop} {
    &:not(:nth-child(n + 6)) {
      display: none;
    }
  }

  ${mediaQueryOnly.largeDesktop} {
    &:not(:nth-child(n + 9)) {
      display: none;
    }
  }

  ${mediaQueryOnly.extraLargeDesktop} {
    &:not(:nth-child(n + 11)) {
      display: none;
    }
  }
`

const moreTab: App.NavItem = {
  text: 'More',
  category: 'tertiary',
}

function NavBarProducts() {
  const [open, toggleOpen, _, closeDropdown] = useToggle()
  const isMobile = useScreenSizeOnly('mobile')
  const moreTabRef = useRef<HTMLButtonElement>(null)
  const navs = useAppSelector(getFlatNavItems)
  const showModal = useContext(ModalContext)

  const onMoreClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(() => {
    if (isMobile) {
      showModal(resolve => <Modal title="More">
        {navs.map((item, index) => <NavListItem
          size="large"
          key={index}
          title={item.text}
          to={item.to}
          href={item.href}
          onClick={resolve}
        />)}
      </Modal>)
    } else {
      toggleOpen()
    }
  }, [isMobile, navs, showModal, toggleOpen])

  return <Group direction="horizontal">
    <ProductTabs direction="horizontal">
      {navs.map(nav => <NavBarTab
        key={nav.id}
        nav={nav}
      />)}
      <MoreNavTab
        onClick={onMoreClick}
        nav={moreTab}
        ref={moreTabRef}
        isDropdown
      />
    </ProductTabs>
    <DropdownList
      size="S"
      triggerRef={moreTabRef}
      anchorRef={moreTabRef}
      open={!isMobile && open}
      onClose={closeDropdown}
      portaled
    >
      {navs.map((item, index) => <NavListItem
        size="medium"
        key={index}
        title={item.text}
        to={item.to}
        href={item.href}
        onClick={closeDropdown}
      />)}
    </DropdownList>
  </Group>
}

export default React.memo(NavBarProducts)
