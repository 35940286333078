import cn from 'clsx'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import NavBarButtonBase from './NavBarButtonBase'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const DropdownIcon = styled(LineAngleDownIcon)`
   margin: 0 -${rem(4)};
`

const BadgeContainer = styled.div`
  position: absolute;
  top: ${rem(0)};
  right: ${rem(0)};
`

const LabelContainer = styled(CSSBreakpoint)`
  padding: 0 ${rem(4)};
`

const Button = styled(NavBarButtonBase)`
  &.size-small {
    height: ${rem(32)};
    padding: ${rem(4)};
  }

  &.size-medium {
    height: ${rem(40)};
    padding: ${rem(8)};
  }

  &.dropdown {
    > ${LabelContainer} {
      padding-right: ${rem(2)};
    }
  }

  &.label {
    padding: ${rem(10)} ${rem(8)};
  }
`

interface Props extends React.PropsWithChildren, React.ComponentProps<typeof NavBarButtonBase> {
  icon?: React.ReactNode;
  badge?: React.ReactNode;
  dropdown?: boolean;
  /**
   * The amount of space the button will take. Alters aspects like padding/height etc
   * @default medium
   */
  size?: 'medium' | 'small';
  /**
   * A breakpoint which the content (children) will not be shown
   */
  contentBreakpointMax?: App.ScreenSize
    /**
   * A breakpoint which the content (children) will not be shown
   */
  contentBreakpointMin?: App.ScreenSize
}

const NavBarButton = React.forwardRef<HTMLButtonElement, Props>(function NavBarButton(props, ref) {
  const {
    children,
    className,
    icon,
    badge,
    dropdown,
    size = 'medium',
    contentBreakpointMax,
    contentBreakpointMin,
    ...buttonProps
  } = props

  const hasChildren = React.Children.count(children) > 0

  return <Button
    {...buttonProps}
    ref={ref}
    className={cn(
      className,
      `size-${size}`, {
        label: hasChildren,
        dropdown,
      })}
  >
    {icon}
    {hasChildren && <LabelContainer min={contentBreakpointMin} max={contentBreakpointMax} >
      {children}
    </LabelContainer>}
    {dropdown && <DropdownIcon />}
    {badge && <BadgeContainer>
      {badge}
    </BadgeContainer> }
  </Button>
})

export default NavBarButton
