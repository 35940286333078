import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const IndentedDivider = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.six};
  height: 1px;
  margin-inline: ${rem(20)};

  ${mediaQueryUp.tablet} {
    margin-inline: ${rem(24)};
  }
`

function NavBarListItemDivider() {
  return <IndentedDivider role="separator" />
}

export default NavBarListItemDivider
