import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import { useAppSelector } from 'hooks/reduxHooks'
import React from 'react'

function AccountMenuDrawerHeader() {
  const accountGivenName = useAppSelector(state => state.auth.account.givenName)

  return <ModalHeader
    title={accountGivenName ? `Hi, ${accountGivenName}` : 'Hello'}
  />
}

export default AccountMenuDrawerHeader
