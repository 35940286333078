import LoadingBox from 'components/Common/Loading/LoadingBox'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import TextLoadingBox from 'components/Luxkit/Typography/TextLoadingBox'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const LoadingContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(12)} ${rem(16)};
  width: ${rem(320)};
  height: ${rem(270)};
`

function AccountMenuLuxLoyaltyDetailsTileSkeleton() {
  return <LoadingContainer>
    <VerticalSpacer gap={20}>
      <VerticalSpacer gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between">
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
        </Group>
        <Group direction="horizontal" horizontalAlign="space-between">
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
          <TextLoadingBox width={120} lines={1} typography="body-medium" />
        </Group>
      </VerticalSpacer>
      <Group direction="horizontal" horizontalAlign="center" gap={20}>
        <LoadingBox width={64} />
        <VerticalSpacer gap={4} >
          <TextLoadingBox width={96} lines={1} typography="body-medium" />
          <TextLoadingBox width={72} lines={1} typography="body-medium" />
        </VerticalSpacer>
      </Group>
      <VerticalSpacer gap={4}>
        <TextLoadingBox width={72} lines={1} typography="body-medium" />
        <TextLoadingBox width={240} lines={1} typography="body-medium" />
        <TextLoadingBox width={240} lines={1} typography="body-medium" />
      </VerticalSpacer>
      <Group direction="horizontal" horizontalAlign="space-between">
        <TextLoadingBox width={72} lines={1} typography="body-medium" />
        <TextLoadingBox width={72} lines={1} typography="body-medium" />
      </Group>
    </VerticalSpacer>
  </LoadingContainer>
}

export default AccountMenuLuxLoyaltyDetailsTileSkeleton
