import React from 'react'
import Badge from 'components/Luxkit/Badge'
import LineShoppingCartIcon from 'components/Luxkit/Icons/line/LineShoppingCartIcon'
import NavBarButton from '../NavBarButton/NavBarButton'
import { useAppSelector } from 'hooks/reduxHooks'
import { getNumberOfActiveUserCartItems } from 'selectors/userCart'
import { isMultiCartEnabled } from 'selectors/featuresSelectors'

interface Props {
  className?: string;
}

/**
 * This is for a not-yet implemented cart feature that is being worked
 * on by the CRO team
 */
function NavBarCartButton(props: Props) {
  const enabled = useAppSelector((state: App.State) => isMultiCartEnabled(state))
  const numberOfActiveItems = useAppSelector(getNumberOfActiveUserCartItems)

  if (!enabled) {
    return null
  }

  return <NavBarButton
    className={props.className}
    data-testid="cart-button"
    to="/cart"
    icon={<LineShoppingCartIcon />}
    badge={!!numberOfActiveItems && <Badge mode="critical" kind="string">
      {numberOfActiveItems}
    </Badge>}
  />
}

export default React.memo(NavBarCartButton)
