import { createSelector } from 'reselect'
import { sum } from 'lib/array/arrayUtils'
import getLuxPlusRedeemedBenefits from 'luxPlus/selectors/benefits/getLuxPlusRedeemedBenefits'

const getLuxPlusTotalValueSaved = createSelector(
  (state: App.State) => getLuxPlusRedeemedBenefits(state),
  (redeemedBenefits): number => {
    if (!redeemedBenefits.length) return 0

    const mappedConfirmedItems = redeemedBenefits.map(item => {
      if (item.unit === 'units') {
        return {
          ...item,
          adjustment: 0,
        }
      }

      return item
    })

    return sum(mappedConfirmedItems, item => item.adjustment)
  },
)

export default getLuxPlusTotalValueSaved
