import NavBar from 'components/NavKit/NavBar'
import useSlimMobileSearch from 'components/SearchV2/Hooks/useSlimMobileSearch'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import config from 'constants/config'
import React from 'react'
import HeaderBar from './HeaderBar'
import NavigationTertiaryHeader from './NavigationHeader/NavigationTertiaryHeader'
import StickyHeaderBar from './StickyHeaderBar'
import SubHeaderBar from './SubHeaderBar'

interface Props {
  stickySearch?: boolean
}

function HeaderMain(props: Props) {
  const { stickySearch } = props

  const HAS_NAV_HEADER = config.businessTraveller.currentAccountMode !== 'business'
  const showSlimSearch = useSlimMobileSearch()

  if (config.NAVKIT_NAVBAR_ENABLED) {
    return <NavBar />
  }

  return <>
    <CSSBreakpoint min="tablet">
      <NavigationTertiaryHeader />
    </CSSBreakpoint>
    {stickySearch && <StickyHeaderBar />}
    {!stickySearch && <HeaderBar />}
    {HAS_NAV_HEADER && !showSlimSearch && <SubHeaderBar />}
  </>
}

export default HeaderMain
