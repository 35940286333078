import { createSelector } from 'reselect'
import { EmptyArray } from 'lib/array/arrayUtils'

const getLuxPlusRedeemedBenefits = createSelector(
  (state: App.State) => state.auth.account.luxPlus.member.subscription.item,
  (subscriptionItem): Array<App.RedeemedMembershipBenefit> => {
    if (!subscriptionItem?.redeemedBenefits.length) return EmptyArray
    return subscriptionItem?.redeemedBenefits.filter(item => item.status === 'CONFIRMED')
  },
)

export default getLuxPlusRedeemedBenefits
