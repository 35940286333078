import React from 'react'
import BodyText from 'components/Luxkit/Typography/BodyText'

interface Props extends React.PropsWithChildren {
  subtitleFormat?: React.ComponentProps<typeof BodyText>['format']
  subtitleLineClamp?: React.ComponentProps<typeof BodyText>['lineClamp']
}

function NavBarListItemSubtitle(props: Props) {
  const { subtitleFormat, subtitleLineClamp, children } = props
  return <BodyText
    variant="small"
    colour="neutral-three"
    format={subtitleFormat}
    lineClamp={subtitleLineClamp}
  >
    {children}
  </BodyText>
}

export default NavBarListItemSubtitle
