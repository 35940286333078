import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import TextButton from 'components/Luxkit/Button/TextButton'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import Flag from 'components/Luxkit/Flag'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import LineUserCircleIcon from 'components/Luxkit/Icons/line/LineUserCircleIcon'
import Modal from 'components/Luxkit/Modal/Modal'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import SlideDown from 'components/utils/SlideDown'
import GeoContext from 'contexts/geoContext'
import ModalContext from 'contexts/ModalContext'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppSelector } from 'hooks/reduxHooks'
import useToggle from 'hooks/useToggle'
import { partitionBy, skip, take } from 'lib/array/arrayUtils'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import React, { useCallback, useContext, useMemo } from 'react'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { getFlatNavItems } from 'selectors/navigationSelectors'
import NavBarContactsItems from '../NavBarButtons/NavBarContactsItems'
import NavBarRegionItems from '../NavBarButtons/NavBarRegionItems'
import NavBarHamburgerMenuItem from './NavBarHamburgerMenuItem'
import NavBarHamburgerMenuLoyaltyItem from './NavBarHamburgerMenuLoyaltyItem'

import NavBarList from '../NavBarList'
import NavBarListItemDivider from '../NavBarListItemDivider'
import Clickable from 'components/Common/Clickable'
import config from 'constants/config'

function NavBarHamburgerMenu() {
  const [showMore, toggleMore] = useToggle()
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const { resolve } = useModalElementContext()
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const navItems = useAppSelector(state => getFlatNavItems(state))
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const loyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)

  const onMoreClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    toggleMore()
    if (!showMore) {
      // toggling it on, scroll it into view
      e.currentTarget.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [showMore, toggleMore])

  const onRegionClick = useCallback(() => {
    showModal(resolve => <Modal
      size="S"
      title="Select your region & currency"
    >
      <NavBarRegionItems onRegionSelect={resolve} />
    </Modal>)
  }, [showModal])
  const onLogin = useCallback(() => showModal(<AccountAccessModal />), [showModal])

  const closeModal = useCallback(() => resolve(), [resolve])

  const hasSomeLoyalty = luxPlusEnabled || loyaltyEnabled

  const [primaryItems, secondaryItems, moreItems] = useMemo(() => {
    const [primaryNavs, otherItems] = partitionBy(navItems, nav => nav.category === 'primary')
    if (navItems.length <= 11) {
      // We show 10 items and put anything else in more, but there's no point
      // in showing a 'more' with one item, hence we break on 11 here, not 10
      return [primaryNavs, otherItems, []]
    }

    const remainingItems = 10 - primaryNavs.length
    return [
      primaryNavs,
      take(otherItems, remainingItems),
      skip(otherItems, remainingItems),
    ]
  }, [navItems])

  return <ModalBase mode="menu">
    <ModalHeader title={<Clickable onClick={closeModal} to={`/${config.defaultHomePage}`}>
      <LogoIcon height="1em" width={null}/>
    </Clickable>}
    />
    <ModalBody>
      <ModalContent flush>
        <NavBarList>
          {primaryItems.map(item => <NavBarHamburgerMenuItem
            key={item.text}
            navItem={item}
            onClick={closeModal}
          />)}
          {!!secondaryItems.length && <>
            <NavBarListItemDivider />
            {secondaryItems.map(item => <NavBarHamburgerMenuItem
              key={item.text}
              navItem={item}
              onClick={closeModal}
            />)}
          </>}
          {!!moreItems.length && <>
            <NavBarListItem
              onClick={onMoreClick}
              title="More"
              endIcon={<DropdownChevron open={showMore} size="S" />}
            />
            <SlideDown show={showMore} animateOpacity>
              {moreItems.map(item => <NavBarHamburgerMenuItem
                key={item.text}
                navItem={item}
                onClick={closeModal}
                hideIcon
                indent
              />)}
            </SlideDown>
          </>}
          <NavBarListItemDivider />
          {hasSomeLoyalty && <>
            {luxPlusEnabled && <NavBarHamburgerMenuLoyaltyItem
              variant="luxPlus"
              onClick={closeModal}
            />}
            {loyaltyEnabled && <NavBarHamburgerMenuLoyaltyItem
              variant="loyalty"
              onClick={closeModal}
              isNew
            />}
            <NavBarListItemDivider />
          </>}
          <NavBarContactsItems />
          <NavBarListItemDivider />
          <NavBarListItem
            title={currentCurrency}
            onClick={onRegionClick}
            startIcon={<Flag countryCode={currentRegionCode} size="S" />}
            endIcon={<CSSBreakpoint max="mobile" as={LineAngleRightIcon} />}
          />
        </NavBarList>
      </ModalContent>
    </ModalBody>
    {!loggedIn && <ModalFooter>
      <TextButton
        kind="secondary"
        onClick={onLogin}
        size="large"
        fit="full-width"
        startIcon={<LineUserCircleIcon />}
      >
        Sign up or Login
      </TextButton>
    </ModalFooter>}
  </ModalBase>
}

export default NavBarHamburgerMenu
