import { useAppHeaderObserver } from 'hooks/useAppLayoutObservers'
import React, { useRef } from 'react'
import NavBarGlobalBar from './NavBarGlobalBar'
import NavBarTertiaryBar from './NavBarTertiaryBar'
import NavBarUtilBar from './NavBarUtilBar'

function NavBar() {
  const ref = useRef<HTMLDivElement>(null)
  useAppHeaderObserver(ref)

  return <div ref={ref}>
    <NavBarTertiaryBar />
    <NavBarUtilBar />
    <NavBarGlobalBar />
  </div>
}

export default React.memo(NavBar)
