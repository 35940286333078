import LineSuitcaseIcon from 'components/Luxkit/Icons/line/LineSuitcaseIcon'
import config from 'constants/config'
import { useAppSelector } from 'hooks/reduxHooks'
import { useOrdersByDepartureStatus } from 'hooks/useOrdersByDepartureStatus'
import React, { ComponentProps, useMemo } from 'react'
import { getPendingOrders } from 'selectors/orderSelectors'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import { sum } from 'lib/array/arrayUtils'
import NavBarListItemIcon from '../NavBarListItemIcon'

type InheritedListItemProps = Pick<ComponentProps<typeof NavBarListItem>, 'onClick'>

function AccountMenuMyEscapesListItem(props: InheritedListItemProps) {
  const { upcoming } = useOrdersByDepartureStatus()
  const pendingOrders = useAppSelector(getPendingOrders)

  const upcomingCopy = useMemo<string | undefined>(() => {
    const numberOfUpcoming = sum([upcoming.length, pendingOrders.length])
    return numberOfUpcoming > 0 ? `${numberOfUpcoming} upcoming` : undefined
  }, [pendingOrders.length, upcoming.length])

  return <NavBarListItem
    {...props}
    startIcon={<NavBarListItemIcon Icon={LineSuitcaseIcon} />}
    title={`My ${config.brandConfig.purchaseSynonym}s`}
    titleFormat="titlecase"
    endTitle={upcomingCopy}
    to="/account/my-escapes"
  />
}

export default AccountMenuMyEscapesListItem
