import React, { useState, useCallback, Fragment } from 'react'
import styled from 'styled-components'
import NavigationMenuItem from './NavigationMenuItem'
import SlideDown from 'components/utils/SlideDown'
import useModalElementContext from 'hooks/Modal/useModalElementContext'

const NavContainer = styled.nav`
  display: block;
`

interface Props {
  navItems: Array<App.NavItem>;
}

function NavigationContainer(props: Props) {
  const {
    navItems,
  } = props
  const [openNav, setOpenNav] = useState<string>()

  const modalElement = useModalElementContext<App.NavItem | undefined>()
  const onNavToggle = useCallback((navItem: App.NavItem) => {
    setOpenNav(openNav === navItem.id ? '' : navItem.id)
    if (!navItem.hasDropdown) {
      modalElement.resolve(navItem)
    }
  }, [modalElement, openNav])

  return <NavContainer role="menu">
    {navItems.map((nav) =>
      <Fragment key={nav.id}>
        <NavigationMenuItem navItem={nav} onClick={onNavToggle} isOpen={openNav === nav.id} />
        {nav.items && nav.items.length > 0 && <SlideDown show={openNav === nav.id}>
          {nav.items?.map((item) => (
            <NavigationMenuItem
              data-testid="NavigationMenuItem"
              key={item.id}
              navItem={item}
              onClick={onNavToggle}
            />
          ))}
        </SlideDown>}
      </Fragment>,
    )}
  </NavContainer>
}

export default NavigationContainer
