import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import Clickable from 'components/Common/Clickable'
import { MediumButtonTypography } from 'components/Luxkit/Typography/ButtonTypography'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import ModalContext from 'contexts/ModalContext'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { rem } from 'polished'
import React, { useCallback, useContext } from 'react'
import styled from 'styled-components'

const LoginButton = styled(Clickable)`
  ${MediumButtonTypography}
  font-family: ${props => props.theme.font.secondary.family};
  font-weight: ${props => props.theme.font.secondary.weight.regular};
  position: relative;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  flex-shrink: 0;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  display: inline-flex;
  align-items: center;
  text-align: center;
  transition: background-color 0.2s;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  border: 1px solid ${props => props.theme.palette.brand.primary.normal};
  color: ${props => props.theme.palette.neutral.default.one};
  height: ${rem(32)};
  width: ${rem(64)};

  ${mediaQueryUp.tablet} {
    height: ${rem(40)};
    width: ${rem(72)};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        color: ${props => props.theme.palette.neutral.default.eight};
        background-color: ${props => props.theme.palette.brand.primary.hover};
      }
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px) solid ${props => props.theme.palette.neutral.default.five};
      outline-offset: 1px;
    }

    &:active {
      background-color: ${props => props.theme.palette.brand.primary.active};
      color: ${props => props.theme.palette.neutral.default.eight};
    }
  }
`

interface Props {
  className?: string;
}

function NavBarLoginButton(props: Props) {
  const { className } = props

  const showModal = useContext(ModalContext)

  const onLoginClick = useCallback(() => showModal(<AccountAccessModal />), [showModal])

  return <LoginButton onClick={onLoginClick} className={className}>
    Log in
  </LoginButton>
}

export default React.memo(NavBarLoginButton)
