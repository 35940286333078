import NavBarListItem from 'components/NavKit/NavBarListItem'
import NavBarListItemIcon from 'components/NavKit/NavBarListItemIcon'
import { getNavigationMenuIcon } from 'content/components/Icon/utils'
import React from 'react'

type InheritedNavBarListItemProps = Pick<React.ComponentProps<typeof NavBarListItem>, 'indent'>

interface Props extends InheritedNavBarListItemProps {
  /**
   * The nav item that represnets this menu item
   */
  navItem: App.NavItem;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Doesn't show any icons if they have one available
   */
  hideIcon?: boolean;
  className?: string;
}

function NavBarHamburgerMenuItem(props: Props) {
  const { navItem, onClick, hideIcon, className, indent } = props

  const iconName = navItem.sideNavIconName || navItem.iconName
  const Icon = iconName && getNavigationMenuIcon(iconName)

  return <NavBarListItem
    className={className}
    title={navItem.text}
    to={navItem.to}
    href={navItem.href}
    startIcon={!hideIcon && Icon && <NavBarListItemIcon Icon={Icon} />}
    onClick={onClick}
    indent={indent}
  />
}

export default NavBarHamburgerMenuItem
