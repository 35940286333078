import { rem } from 'polished'
import cn from 'clsx'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'
import Clickable from '../Common/Clickable'
import BodyText from '../Luxkit/Typography/BodyText'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import { mediaQueryUp } from 'components/utils/breakpoint'
import NavBarListItemTitle from './NavBarListItemTitle'
import NavBarListItemSubtitle from './NavBarListItemSubtitle'
import Group from 'components/utils/Group'

const Item = styled(Clickable)`
  display: flex;
  align-items: start;
  gap: ${rem(12)};
  width: 100%;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  border-radius: ${props => props.theme.borderRadius.S};
  box-shadow: none;
  color: ${props => props.theme.palette.neutral.default.one};
  padding-block: ${rem(8)};

  &:not(.indent) {
    padding-inline: ${rem(20)};
  
    ${mediaQueryUp.tablet} {
      padding-inline: ${rem(24)};
    }
  }
  
  &.indent {
    padding-inline: ${rem(48)} ${rem(20)};

    ${mediaQueryUp.tablet} {
      padding-inline: ${rem(52)} ${rem(24)};
    }
  }

  &.selected {
    background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  }

  &:disabled {
    color: ${props => props.theme.palette.neutral.default.four};
  }

  &:not(:disabled, .nonInteractable) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      box-shadow: var(${KEYBOARD_MODE_CSS_VAR}, 0 0 0 2px ${props => props.theme.palette.neutral.default.five} inset);
    }
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`

interface Props extends
  Omit<ComponentProps<typeof Clickable>, 'title' | 'children'>,
  ComponentProps<typeof NavBarListItemTitle>,
  ComponentProps<typeof NavBarListItemSubtitle> {
  /** An icon element that will go at the start (often the 'left' side) of the AccountMenuListItem item. */
  startIcon?: React.ReactNode
  /**
   * An element that will go at the end i.e. right side of the AccountMenuListItem item.
   * i.e. badge icon or supporting copy like '2 upcoming'
   */
  title: React.ReactNode
  subtitle?: React.ReactNode
  className?: string
  /**
   * Children will be placed in the 'slot' specified in Figma.
   */
  children?: React.ReactNode
  /**
   * Disables the visual interactability feedbacks such as hover and focus.
   *
   * Turns the component into a `div`.
   */
  nonInteractable?: boolean
  endTitle?: React.ReactNode
  endIcon?: React.ReactNode
  indent?: boolean
}

function NavBarListItem(props: Props) {
  const {
    startIcon,
    className,
    children,
    nonInteractable,
    disabled,
    endTitle,
    endIcon,
    indent,
    title,
    titleFormat,
    titleLineClamp,
    titleColour,
    subtitle,
    subtitleFormat,
    subtitleLineClamp,
    ...buttonProps
  } = props

  return (
    <Item
      {...buttonProps}
      disabled={disabled}
      as={nonInteractable ? ('div' as any) : undefined}
      className={cn(
        className,
        {
          nonInteractable,
          indent,
        },
      )}
    >
      {!!startIcon && <div>
        {startIcon}
      </div>}

      <Content>
        <Group direction="vertical" noDisplayWhenEmpty>
          {!!title && <NavBarListItemTitle
            titleFormat={titleFormat}
            titleLineClamp={titleLineClamp}
            titleColour={titleColour}
          >
            {title}
          </NavBarListItemTitle>}
          {!!subtitle && <NavBarListItemSubtitle
            subtitleFormat={subtitleFormat}
            subtitleLineClamp={subtitleLineClamp}
          >
            {subtitle}
          </NavBarListItemSubtitle>}
        </Group>

        {children}

        <div>
          {!!endTitle && <BodyText
            variant="medium"
            colour="neutral-two"
          >
            {endTitle}
          </BodyText>}
          {endIcon}
        </div>

      </Content>
    </Item>
  )
}

export default NavBarListItem
