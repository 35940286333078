import { matchPath, useLocation } from 'react-router'
import config from 'constants/config'
import { useMemo } from 'react'

function useIsHomepage(): boolean {
  const location = useLocation()
  return useMemo(() => {
    return !!matchPath(location.pathname, { path: `/:regionCode/${config.defaultHomePage}`, exact: true })
  }, [location.pathname])
}

export default useIsHomepage
