import { useAppSelector } from 'hooks/reduxHooks'
import getLuxLoyaltyDisplayTier from 'luxLoyalty/selectors/getLuxLoyaltyDisplayTier'

/**
 * Returns the current loyalty teir for the user
 * If not entered in the program, will return the 'base' default tier
 */
function useCurrentLoyaltyTier() {
  const tier = useAppSelector(state => getLuxLoyaltyDisplayTier(state))

  return tier
}

export default useCurrentLoyaltyTier
