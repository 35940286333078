import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'

const offerPageRoutes = [
  '/:region/offer',
  '/:region/partner',
  '/:region/tour',
  '/:region/cruises/:slug?/:offerId',
  '/:region/experience',
]

/**
 * Tells us whether we are currently on an offer page or not
 */
function useIsOfferPage() {
  const location = useLocation()
  return useMemo(() => {
    return !!matchPath(location.pathname, { path: offerPageRoutes })
  }, [location.pathname])
}

export default useIsOfferPage
