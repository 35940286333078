import { fetchOrders } from 'actions/OrderActions'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { haveOrdersBeenFetched } from 'lib/order/orderUtils'
import { useEffect, useMemo } from 'react'
import { getUpcomingOrders } from 'selectors/orderSelectors'

function useOrders(status?: 'upcoming'): [Array<App.Order>, boolean, any] {
  const dispatch = useAppDispatch()
  const orders = useAppSelector(state => getUpcomingOrders(state))
  const fetching = useAppSelector(state => haveOrdersBeenFetched(state, status))
  const errors = useAppSelector(state => state.orders.orderErrors)

  useEffect(() => {
    dispatch(fetchOrders({ status }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const error = useMemo(() => orders.find(order => errors[order.id]), [errors, orders])

  return [orders, fetching, error]
}

export default useOrders
