import React from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import config from 'constants/config'
import { capitalise } from 'lib/string/stringUtils'
import LineSuitcaseIcon from 'components/Luxkit/Icons/line/LineSuitcaseIcon'

interface Props {
  className?: string;
}

function NavBarMyEscapesButton(props: Props) {
  return <NavBarButton
    className={props.className}
    icon={<LineSuitcaseIcon />}
    to="/account/my-escapes"
  >
    My {capitalise(config.brandConfig.purchaseSynonym)}s
  </NavBarButton>
}

export default React.memo(NavBarMyEscapesButton)
