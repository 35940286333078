import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import useCurrentGlobalSearchVerticalPage from 'hooks/GlobalSearch/useCurrentGlobalSearchVerticalPage'
import useGlobalSearchURLHashVertical from 'hooks/GlobalSearch/useGlobalSearchURLHashVertical'
import useGlobalSearchVerticalTabs from 'hooks/GlobalSearch/useGlobalSearchVerticalTabs'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import NavBarButton from '../NavBarButton/NavBarButton'
import { mediaQueryUp } from 'components/utils/breakpoint'
import useIsSearchPage from 'hooks/Navigation/useIsSearchPage'
import useIsHomepage from 'home/hooks/isHomepage'

const IconSearchInput = styled(NavBarButton)`
  ${mediaQueryUp.tablet} {
    display: none;
  }
`

interface Props {
  className?: string;
}

function NavBarSearchButton(props: Props) {
  const { className } = props
  const globalSearchVerticalTabs = useGlobalSearchVerticalTabs()
  const currentSearchVerticalPage = useCurrentGlobalSearchVerticalPage()
  const { toggleSearchVertical } = useGlobalSearchURLHashVertical()

  const isSearchPage = useIsSearchPage()
  const isHomepage = useIsHomepage()

  const onSearchClick = useCallback(() => {
    const verticalTab = currentSearchVerticalPage ?? globalSearchVerticalTabs.keys().next().value
    toggleSearchVertical(verticalTab)
  }, [currentSearchVerticalPage, globalSearchVerticalTabs, toggleSearchVertical])

  // Don't need he search button on a search page
  if (isSearchPage || isHomepage) {
    return null
  }

  return <IconSearchInput
    className={className}
    icon={<LineSearchIcon />}
    onClick={onSearchClick}
  />
}

export default React.memo(NavBarSearchButton)
