import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineUserCircleIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M12 2a9.9999 9.9999 0 0 0-7.35 16.76A10.001 10.001 0 0 0 12 21.9793a9.9994 9.9994 0 0 0 7.35-3.2193 9.9993 9.9993 0 0 0-1.8912-15.1263A9.9998 9.9998 0 0 0 12 2Zm0 18a7.9996 7.9996 0 0 1-5.55-2.25A5.9997 5.9997 0 0 1 12 14.0298a6.0002 6.0002 0 0 1 5.55 3.7202A7.9995 7.9995 0 0 1 12 20Zm-2-10a1.9998 1.9998 0 0 1 1.2347-1.8478A1.9999 1.9999 0 1 1 10 10Zm8.91 6A7.9998 7.9998 0 0 0 15 12.62a3.9996 3.9996 0 0 0-.83-6.006 4.0003 4.0003 0 0 0-5.8157 1.7145A4 4 0 0 0 9 12.62 8 8 0 0 0 5.09 16 7.9199 7.9199 0 0 1 4 12a8 8 0 1 1 16 0 7.9193 7.9193 0 0 1-1.09 4Z"/>
  </SvgIcon>
}

export default LineUserCircleIcon
