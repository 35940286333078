import React from 'react'
import SvgIcon, { SvgIconProps } from 'components/Luxkit/SvgIcon'

function LineCreditCardIcon(props: SvgIconProps) {
  return <SvgIcon {...props} fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" d="M7 15h3a1.0001 1.0001 0 0 0 0-2H7a1.0002 1.0002 0 0 0-.7071 1.7071C6.4804 14.8946 6.7348 15 7 15ZM19 5H5a3 3 0 0 0-3 3v9a2.9998 2.9998 0 0 0 3 3h14a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3Zm1 12a1.0001 1.0001 0 0 1-1 1H5a1.0002 1.0002 0 0 1-1-1v-6h16v6Zm0-8H4V8a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v1Z"/>
  </SvgIcon>
}

export default LineCreditCardIcon
