import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import { rem } from 'polished'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import NavBarButtonBase from '../NavBarButton/NavBarButtonBase'
import { matchPath, useLocation } from 'react-router'

const Tab = styled(NavBarButtonBase)`
  padding: 0 ${rem(12)};
  height: ${rem(48)};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${props => props.theme.font.primary.weight.bold};
  position: relative;
`

const ActiveIndicator = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${rem(4)};
  opacity: 1;
  transition: opacity 0.2s;
  width: 100%;
  background-color: ${props => props.theme.palette.brand.primary.normal};

  &[hidden] {
    opacity: 0;
  }
`

interface Props extends React.ComponentProps<typeof NavBarButtonBase> {
  nav: App.NavItem;
  isDropdown?: boolean;
}

const NavBarTab = React.forwardRef<HTMLButtonElement, Props>(function NavBarTab(props, ref) {
  const { nav, isDropdown, ...buttonProps } = props
  const location = useLocation()
  const isActive = useMemo(() => {
    if (nav.to) {
      return !!matchPath(location.pathname, { path: `/:regionCode${nav.to}` })
    }
  }, [location.pathname, nav.to])

  return <Tab
    {...buttonProps}
    to={nav.to}
    href={nav.href}
    ref={ref}
  >
    <span>{nav.text}</span>
    {isDropdown && <LineAngleDownIcon />}
    <ActiveIndicator hidden={!isActive}/>
  </Tab>
})

export default NavBarTab
