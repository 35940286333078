import { TypographyElement } from 'components/Luxkit/Typography/Typography'
import devOnlyCss, { InvalidElementStyle } from 'lib/styles/devOnlyCss'
import React, { forwardRef, PropsWithChildren } from 'react'
import styled from 'styled-components'

const Element = styled.span`
  &.colour-inherit { color: inherit; }
  &.colour-critical { color: ${props => props.theme.palette.messaging.critical.normalForeground}; }
  &.colour-exclusive-contrast { color: ${props => props.theme.palette.product.exclusive.contrast}; }
  &.colour-highlight-primary { color: ${props => props.theme.palette.highlight.primary.normalForeground}; }
  &.colour-highlight-secondary { color: ${props => props.theme.palette.highlight.secondary.normalForeground}; }
  &.colour-lux-plus { color: ${props => props.theme.palette.product.luxPlus.foreground}; }
  &.colour-neutral-eight { color: ${props => props.theme.palette.neutral.default.eight}; }
  &.colour-neutral-five { color: ${props => props.theme.palette.neutral.default.five}; }
  &.colour-neutral-four { color: ${props => props.theme.palette.neutral.default.four}; }
  &.colour-neutral-one { color: ${props => props.theme.palette.neutral.default.one}; }
  &.colour-neutral-six { color: ${props => props.theme.palette.neutral.default.six}; }
  &.colour-neutral-three { color: ${props => props.theme.palette.neutral.default.three}; }
  &.colour-neutral-two { color: ${props => props.theme.palette.neutral.default.two}; }
  &.colour-primary { color: ${props => props.theme.palette.brand.primary.normal}; }
  &.colour-primary-dark { color: ${props => props.theme.palette.brand.primary.dark}; }
  &.colour-success { color: ${props => props.theme.palette.messaging.success.normalForeground}; }
  &.colour-success-dark { color: ${props => props.theme.palette.messaging.success.darkForeground}; }
  &.colour-urgency { color: ${props => props.theme.palette.messaging.urgency.normalForeground}; }
  &.colour-warning { color: ${props => props.theme.palette.messaging.warning.normalForeground}; }
  &.colour-tier-bronze-contrast { color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast}; }
  &.colour-tier-silver-contrast { color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast}; }
  &.colour-tier-gold-contrast { color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast}; }
  &.colour-tier-platinum-contrast { color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast}; }
  ${devOnlyCss`
    &:not(${TypographyElement} &) { ${InvalidElementStyle('<Colour> should be child of a typography')} }
  `}
`

type InlineElements = Pick<React.JSX.IntrinsicElements, 'span' | 'b' | 'strong' | 'i' | 'em' | 's' | 'sup' | 'sub'>

interface Props extends PropsWithChildren {
  /**
   * @default span
   */
  as?: keyof InlineElements
  value: 'inherit'
    | 'critical'
    | 'exclusive-contrast'
    | 'highlight-primary'
    | 'highlight-secondary'
    | 'lux-plus'
    | 'neutral-eight'
    | 'neutral-five'
    | 'neutral-four'
    | 'neutral-one'
    | 'neutral-six'
    | 'neutral-three'
    | 'neutral-two'
    | 'primary-dark'
    | 'primary'
    | 'success-dark'
    | 'success'
    | 'urgency'
    | 'warning'
    | 'tier-bronze-contrast'
    | 'tier-silver-contrast'
    | 'tier-gold-contrast'
    | 'tier-platinum-contrast'
}

const Colour = forwardRef<HTMLSpanElement, Props>(function Colour(
  {
    children,
    as = 'span',
    value,
  },
  ref,
) {
  return <Element
    as={as}
    ref={ref}
    className={`colour-${value}`}
  >
    {children}
  </Element>
})

export default Colour
