import Flag from 'components/Luxkit/Flag'
import LineCommentsIcon from 'components/Luxkit/Icons/line/LineCommentsIcon'
import LineEnvelopeIcon from 'components/Luxkit/Icons/line/LineEnvelopeIcon'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import NavBarListItemIcon from 'components/NavKit/NavBarListItemIcon'
import config from 'constants/config'
import { SUPPORT_EMAIL_FOR_BRAND } from 'constants/support'
import ContactContext from 'contexts/contactContext'
import GeoContext from 'contexts/geoContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import noop from 'lib/function/noop'
import { getSVGImageUrl } from 'lib/image/imageUtils'
import shouldRenderTimely from 'lib/timely/timely'
import React, { MouseEventHandler, useCallback, useContext } from 'react'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'
import NavBarListItem from 'components/NavKit/NavBarListItem'

interface Props {
  onItemClick?: () => void;
}

function NavBarContactsItems(props: Props) {
  const {
    onItemClick = noop,
  }: Props = props

  const supportAssistantEnabled = useAppSelector(isSupportAssistantEnabled)
  const { currentRegionCode, currentRegionName } = useContext(GeoContext)
  const currentContact = useContext(ContactContext)

  const localContact = currentContact.local
  const internationalContact = currentContact.international
  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)

  const showTimelyPopup = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault()
    onItemClick()
    window.open(config.TIMELY_LINK, 'popup', 'width=600,fullscreen=no,location=no,menubar=no,toolbar=no')
  }, [onItemClick])

  return (<>
    <NavBarListItem
      aria-label={`Local Sales & Support contact - ${currentRegionName}`}
      href={`tel:${localContact.number}`}
      startIcon={config.businessTraveller.isEnabled ? <Flag countryCode={currentRegionCode} size="S"/> : <NavBarListItemIcon Icon={LinePhoneIcon} />}
      title="Local Sales & Support"
      onClick={onItemClick}
      rel="nofollow"
      subtitle={localContact.humanReadable}
    />
    {supportAssistantEnabled && <NavBarListItem
      aria-label={`Luxury Escapes Support Assistant - ${currentRegionName}`}
      to="/support"
      startIcon={<NavBarListItemIcon Icon={LineCommentsIcon} />}
      title="Chat now"
      onClick={onItemClick}
      rel="nofollow"
      subtitle="Luxury Escapes Support Assistant"
    />}
    {shouldRenderWhatsApp && <NavBarListItem
      title="WhatsApp"
      aria-label={`WhatsApp - ${currentRegionName}`}
      startIcon={<img src={getSVGImageUrl('whatsapp-logo')} width="20" height="20" alt="WhatsApp"/>}
      onClick={onItemClick}
      href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
      target="_blank"
      rel="noopener"
    />}
    {shouldRenderTimely(currentRegionCode) && <NavBarListItem
      title="Book store appointment"
      aria-label={`Timely - ${currentRegionName}`}
      startIcon={<img src={getSVGImageUrl('logo-LE')} width="20" height="20" alt="support"/>}
      onClick={showTimelyPopup}
      href={config.TIMELY_LINK}
      target="popup"
      rel="noopener"
    />}
    <NavBarListItem
      startIcon={config.businessTraveller.isEnabled ?
        <img src={getSVGImageUrl('logo-LE')} width="20" height="20" alt="support"/> :
        <NavBarListItemIcon Icon={LinePhoneIcon} />}
      aria-label="International Support contact"
      href={`tel:${internationalContact.number}`}
      title="International Support"
      onClick={onItemClick}
      rel="nofollow"
      subtitle={internationalContact.humanReadable}
    />
    {config.businessTraveller.currentAccountMode === 'business' && <NavBarListItem
      startIcon={<NavBarListItemIcon Icon={LineEnvelopeIcon} />}
      aria-label="Email"
      href={`mailto:${SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}`}
      title="Email"
      onClick={onItemClick} // closes
      rel="nofollow"
      subtitle={SUPPORT_EMAIL_FOR_BRAND[config.BRAND]}
    />}
  </>)
}

export default React.memo(NavBarContactsItems)
