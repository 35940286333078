import React, { useCallback, useContext, useMemo, useRef } from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import LineBellIcon from 'components/Luxkit/Icons/line/LineBellIcon'
import useUserNotifications from 'hooks/Notifications/useUserNotifications'
import Badge from 'components/Luxkit/Badge'
import ModalContext from 'contexts/ModalContext'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import Modal from 'components/Luxkit/Modal/Modal'
import NarBarNotificationsContent from './NarBarNotificationsContent'
import { viewAllUserNotifications } from 'actions/UserNotificationActions'
import { useAppDispatch } from 'hooks/reduxHooks'

interface Props {
  className?: string;
}

function NavBarNotificationsButton(props: Props) {
  const [isOpen, toggleOpen, _, closeDropdown] = useToggle()
  const dispatch = useAppDispatch()
  const showModal = useContext(ModalContext)
  const isMobileScreen = useScreenSizeOnly('mobile')
  const [notifications] = useUserNotifications()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const newNotificationCount = useMemo(() => notifications.filter(item => !item.viewed).length, [notifications])

  const onNavClick = useCallback(async() => {
    if (isMobileScreen) {
      await showModal(<Modal title="Notifications">
        <NarBarNotificationsContent />
      </Modal>)
      dispatch(viewAllUserNotifications())
    } else {
      if (isOpen) {
        dispatch(viewAllUserNotifications())
      }
      toggleOpen()
    }
  }, [dispatch, isMobileScreen, isOpen, showModal, toggleOpen])

  const onDropdownClose = useCallback(() => {
    dispatch(viewAllUserNotifications())
    closeDropdown()
  }, [closeDropdown, dispatch])

  return <>
    <NavBarButton
      ref={buttonRef}
      className={props.className}
      onClick={onNavClick}
      icon={<LineBellIcon />}
      badge={!!newNotificationCount && <Badge mode="critical" kind="string">
        {newNotificationCount}
      </Badge>}
    />
    <DropdownList
      open={isOpen && !isMobileScreen}
      size="M"
      width="min"
      anchorRef={buttonRef}
      triggerRef={buttonRef}
      onClose={onDropdownClose}
    >
      <NarBarNotificationsContent />
    </DropdownList>
  </>
}

export default React.memo(NavBarNotificationsButton)
