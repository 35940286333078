import GoogleLongLogo from 'components/Common/TrustIndicators/GoogleLongLogo'
import { TrustPilotLogo } from 'components/Common/TrustIndicators/TrustPilotLogo'
import AtolLogoIcon from 'components/Luxkit/Icons/AtolLogoIcon'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Group from 'components/utils/Group'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

// The logo doesn't quite have a nice centre line, we need to nudge it up to make it line up
const NudgedTrustPilotLogo = styled(TrustPilotLogo)`
  transform: translateY(-3px);
`

export type NavUspType = 'google' | 'trustpilot' | 'atol'

interface Props {
  type: NavUspType;
}

function NavBarUSP(props: Props) {
  const { type } = props

  switch (type) {
    case 'atol':
      return <BodyTextBlock
        variant="small"
        startIcon={<AtolLogoIcon colour="neutral-one" />}
        colour="neutral-two"
      >
        ATOL protected
      </BodyTextBlock>
    case 'google':
      return <Group direction="horizontal" gap={4} verticalAlign="center">
        <GoogleLongLogo height={rem(14)} />
        <BodyText variant="small">4.7/5</BodyText>
      </Group>
    case 'trustpilot':
      return <Group direction="horizontal" gap={4} verticalAlign="center">
        <NudgedTrustPilotLogo height={rem(16)} />
        <BodyText as="span" variant="small">4.8/5</BodyText>
      </Group>
    default:
      return null
  }
}

export default React.memo(NavBarUSP)
