import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import withMountGuard from 'hocs/withMountGuard'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxLoyaltyProgramNameLogo from 'luxLoyalty/components/LuxLoyaltyProgramNameLogo'
import { LUX_LOYALTY } from 'luxLoyalty/constants/base'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Caption from 'components/Luxkit/Typography/Caption'
import Divider from 'components/Luxkit/Divider'
import getLuxLoyaltyTierDisplayName from 'luxLoyalty/selectors/getLuxLoyaltyTierDisplayName'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import { clamp } from 'lib/maths/mathUtils'
import LuxLoyaltyAccountTileCircularProgressBar from 'luxLoyalty/components/LuxLoyaltyAccountTileCircularProgressBar'
import cn from 'clsx'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Clickable from 'components/Common/Clickable'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import BodyText from 'components/Luxkit/Typography/BodyText'
import TextLink from 'components/Luxkit/TextLink'
import LineChairWithAirplaneIcon from 'components/Luxkit/Icons/line/LineChairWithAirplaneIcon'
import LineRoomUpgradeIcon from 'components/Luxkit/Icons/line/LineRoomUpgradeIcon'
import LineTaxiIcon from 'components/Luxkit/Icons/line/LineTaxiIcon'
import { nonNullable, arrayToObject } from 'lib/array/arrayUtils'
import AccountDrawerBenefitHighlightItem from './AccountDrawerBenefitHighlightItem'
import FormatLuxLoyaltyStatusCredits from 'luxLoyalty/components/Formatters/FormatLuxLoyaltyStatusCredits'
import FormatDateTime from 'components/utils/Formatters/FormatDateTime'
import getLuxLoyaltyNextTier from 'luxLoyalty/selectors/getLuxLoyaltyNextTier'

const Container = styled(Clickable)`
  padding: ${rem(12)} ${rem(16)};
  color: ${props => props.theme.palette.product.luxPlus.contrast};
  border-radius: ${props => props.theme.borderRadius.M};
  /** To see the effect of the border-radius */
  overflow: hidden;
  
  &.tier-bronze {
    background: ${props => props.theme.palette.product.luxLoyalty.bronze.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
  }
  
  &.tier-silver {
    background: ${props => props.theme.palette.product.luxLoyalty.silver.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
  }
  
  &.tier-gold {
    background: ${props => props.theme.palette.product.luxLoyalty.gold.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
  }
  
  &.tier-platinum {
    background: ${props => props.theme.palette.product.luxLoyalty.platinum.gradient};
    color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

const TIER_BENEFITS: Record<App.LuxLoyaltyTier, Array<App.LuxLoyaltyBenefit['code']>> = {
  bronze: [],
  silver: ['lounge_pass'],
  gold: ['airport_transfer', 'hotel_upgrade', 'lounge_pass'],
  platinum: ['airport_transfer', 'hotel_upgrade', 'lounge_pass'],
}

const BENEFIT_CONFIG: Utils.PartialRecord<App.LuxLoyaltyBenefit['code'], {
  title: string;
  icon: React.ReactElement;
}> = {
  lounge_pass: {
    title: 'Free airport lounge passes',
    icon: <LineChairWithAirplaneIcon />,
  },
  airport_transfer: {
    title: 'Free airport chauffeur service',
    icon: <LineTaxiIcon />,
  },
  hotel_upgrade: {
    title: 'Guaranteed free room upgrade',
    icon: <LineRoomUpgradeIcon />,
  },
}

interface BenefitConfigItem {
  code: App.LuxLoyaltyBenefit['code'];
  displayInfo: {
    title: string;
    icon: React.ReactElement;
  };
}

function getTierBenefitDisplayInfo(tier: App.LuxLoyaltyTier): Array<BenefitConfigItem> {
  const tierBenefits = TIER_BENEFITS[tier]
  const displayInfoItems = tierBenefits.map(code => {
    const displayInfo = BENEFIT_CONFIG[code]
    return displayInfo ? { code, displayInfo } : undefined
  })
  return nonNullable(displayInfoItems)
}

const getUpgradeMessage = (accountTier: App.LuxLoyaltyTier, nextTier: App.LuxLoyaltyTier | undefined) => {
  if (accountTier === 'platinum') {
    return `to retain ${getLuxLoyaltyTierDisplayName(accountTier)}`
  }

  if (nextTier) {
    return `to unlock ${getLuxLoyaltyTierDisplayName(nextTier)}`
  }

  return ''
}

interface Props {
  onLinkClick: () => void
  luxLoyaltyAccountData: App.LuxLoyaltyAccount
}

function AccountMenuLuxLoyaltyDetailsTile({ onLinkClick, luxLoyaltyAccountData }: Props) {
  const nextTier = useAppSelector(state => getLuxLoyaltyNextTier(state))
  const accountTier = luxLoyaltyAccountData.tier
  const benefits = luxLoyaltyAccountData.benefits
  const points = luxLoyaltyAccountData.points
  const statusCredits = luxLoyaltyAccountData.statusCredits
  const totalCreditsForTierRetention = luxLoyaltyAccountData.totalCreditsForTierRetention ?? 0
  const totalCreditsForTierUpgrade = luxLoyaltyAccountData.totalCreditsForTierUpgrade ?? 0
  const nextTierCreditsRequired = accountTier === 'platinum' ? totalCreditsForTierRetention : totalCreditsForTierUpgrade
  const statusCreditsRemaining = Math.max(0, nextTierCreditsRequired - statusCredits)
  const progress = Math.floor((statusCredits / (accountTier === 'platinum' ? totalCreditsForTierRetention : nextTierCreditsRequired)) * 100)
  const clampedProgress = clamp(0, progress, 100)

  const benefitsMap = arrayToObject(benefits, benefit => benefit.code)
  const tierBenefitsDisplayInfo = getTierBenefitDisplayInfo(accountTier)

  const validBenefitsDisplayInfo = nonNullable(
    tierBenefitsDisplayInfo.map(displayInfo =>
      benefitsMap[displayInfo.code] ? displayInfo : undefined,
    ),
  )

  return <Container to={`/${LUX_LOYALTY.LANDING_PAGE}`} onClick={onLinkClick} className={cn(`tier-${accountTier}`)}>
    <VerticalSpacer gap={12}>
      <VerticalSpacer gap={4}>
        <Group direction="horizontal" horizontalAlign="space-between">
          <LuxLoyaltyProgramNameLogo width={72} />
          {accountTier && <BodyText variant="medium" format="uppercase">{getLuxLoyaltyTierDisplayName(accountTier)}</BodyText>}
        </Group>
        <Group direction="horizontal" horizontalAlign="space-between" verticalAlign="end">
          <Caption variant="large">Member {luxLoyaltyAccountData.accountNumber}</Caption>
          <Group direction="horizontal" gap={4} verticalAlign="center">
            <Heading variant="heading6">
              <FormatDecimal value={points} />
            </Heading>
            <Caption variant="medium" weight="normal">pts</Caption>
          </Group>
        </Group>
      </VerticalSpacer>
      <VerticalSpacer gap={20}>
        <Group direction="horizontal" verticalAlign="center" horizontalAlign="center" gap={20}>
          <LuxLoyaltyAccountTileCircularProgressBar
            progress={clampedProgress}
            tier={accountTier}
          >
            <Group direction="vertical" horizontalAlign="center" gap={2}>
              <BodyText variant="medium" weight="bold">
                <FormatDecimal value={statusCredits} />
              </BodyText>
              <Divider kind="primary" />
              <Caption variant="small">
                <FormatDecimal value={nextTierCreditsRequired} />
              </Caption>
            </Group>
          </LuxLoyaltyAccountTileCircularProgressBar>
          <BodyText variant="small">
            <b><FormatLuxLoyaltyStatusCredits statusCredits={statusCreditsRemaining} /></b>
            <br/>
            {getUpgradeMessage(accountTier, nextTier)}
          </BodyText>
        </Group>
        {validBenefitsDisplayInfo && validBenefitsDisplayInfo.length > 0 && <VerticalSpacer gap={8}>
          <BodyText variant="small" weight="bold">Your top benefits</BodyText>
          {validBenefitsDisplayInfo.map(({ code, displayInfo }, index) => (
            <React.Fragment key={code}>
              {index > 0 && <Divider kind="secondary" />}
              <AccountDrawerBenefitHighlightItem
                benefit={benefitsMap[code]}
                tier={accountTier}
                title={displayInfo.title}
                icon={displayInfo.icon}
              />
            </React.Fragment>
          ))}
        </VerticalSpacer>}
        <Group direction="horizontal" horizontalAlign="space-between">
          {luxLoyaltyAccountData.statusReviewDate && <Caption variant="medium">Review date{' '}
            <FormatDateTime value={luxLoyaltyAccountData.statusReviewDate} format="day-month-name" />
          </Caption>}
          <TextLink size="small">
            View membership
          </TextLink>
        </Group>
      </VerticalSpacer>
    </VerticalSpacer>
  </Container>
}

export default withMountGuard(() => {
  return useAppSelector(getIsLuxLoyaltyEnabled)
})(AccountMenuLuxLoyaltyDetailsTile)
