import React, { ComponentProps, useMemo } from 'react'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import NavBarListItemIcon from 'components/NavKit/NavBarListItemIcon'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import noop from 'lib/function/noop'
import { useTrips } from 'tripPlanner/hooks/api/trip'
import moment from 'moment'

type InheritedListItemProps = Pick<ComponentProps<typeof NavBarListItem>, 'onClick'>

function AccountMenuTripPlannerListItem(props: InheritedListItemProps) {
  const { data: trips } = useTrips({ onError: noop, onSuccess: noop })
  const upcomingTrips = useMemo(() => trips?.filter((trip) => !trip.endDate || trip.endDate > moment()) || [], [trips])

  const numberOfUpcomingTrips = upcomingTrips.length
  const upcomingCopy = numberOfUpcomingTrips > 0 ? `${numberOfUpcomingTrips} upcoming` : undefined

  return <NavBarListItem
    {...props}
    startIcon={<NavBarListItemIcon Icon={LineHeartIcon} colour="primary" />}
    title="Trip Plans"
    endTitle={upcomingCopy}
    to="/trip-planner"
  />
}

export default AccountMenuTripPlannerListItem
