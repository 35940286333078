import React from 'react'
import styled from 'styled-components'
import LuxPlusLogo from 'luxPlus/components/Logos/LuxPlusLogo'
import LuxLoyaltyProductLogo from 'luxLoyalty/components/LuxLoyaltyProductLogo'
import Badge from 'components/Luxkit/Badge'
import { rem } from 'polished'
import { mediaQueryUp } from 'components/utils/breakpoint'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import Clickable from 'components/Common/Clickable'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 ${rem(4)};
  transition: background-color 0.2s;

  ${mediaQueryUp.tablet} {
    padding: 0 ${rem(8)};
  }
`

const LogoButton = styled(Clickable)`
  position: relative;
  white-space: nowrap;
  justify-content: center;
  text-decoration: none;
  flex-shrink: 0;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  align-items: center;
  text-align: center;

  display: flex;
  gap: ${rem(4)};
  height: ${rem(32)};

  ${mediaQueryUp.tablet} {
    height: ${rem(40)};
  }

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover > ${Logo} {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px) solid ${props => props.theme.palette.neutral.default.five};
      outline-offset: 1px;
    }

    &:active > ${Logo} {
      background-color: ${props => props.theme.palette.neutral.default.six};
    }
  }
`

const NewBadge = styled(Badge)`
  position: absolute;
  top: ${rem(-8)};
  right: ${rem(-8)};
`

interface Props {
  isNew?: boolean;
  variant: 'luxPlus' | 'loyalty';
}

function NavBarLoyaltyLogo(props: Props) {
  const { variant, isNew } = props

  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))

  return <LogoButton
    to={variant === 'luxPlus' ? '/lux-plus' : '/societe'}
  >
    <CSSBreakpoint min="desktop">
      <BodyText variant="small" colour="neutral-three">
        {variant === 'luxPlus' && `${!isLuxPlusMember ? 'Join our ' : ''}VIP Travel Club`}
        {variant === 'loyalty' && 'Earn points with'}
      </BodyText>
    </CSSBreakpoint>
    <Logo>
      {variant === 'luxPlus' && <LuxPlusLogo height={12} />}
      {variant === 'loyalty' && <LuxLoyaltyProductLogo height={19}/>}
    </Logo>
    {isNew && <NewBadge mode="critical" kind="string">New</NewBadge>}
  </LogoButton>
}

export default React.memo(NavBarLoyaltyLogo)
