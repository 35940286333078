import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router'

const searchPageRoutes = [
  '/:regionCode/search',
  '/:regionCode/flights-search-results',
]

/**
 * Tells us whether we are currently on an offer page or not
 */
function useIsSearchPage() {
  const location = useLocation()
  return useMemo(() => {
    return !!matchPath(location.pathname, { path: searchPageRoutes })
  }, [location.pathname])
}

export default useIsSearchPage
