import React, { useContext, useRef } from 'react'
import NavBarButton from '../NavBarButton/NavBarButton'
import GeoContext from 'contexts/geoContext'
import CurrencyContext from 'contexts/currencyContext'
import Flag from 'components/Luxkit/Flag'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'
import useToggle from 'hooks/useToggle'
import config from 'constants/config'
import NavBarRegionItems from './NavBarRegionItems'

interface Props {
  className?: string;
}

function NavBarRegionButton(props: Props) {
  const [open, toggleOpen, _, closeDropdown] = useToggle()
  const currentCurrency = useContext(CurrencyContext)
  const { currentRegionCode } = useContext(GeoContext)
  const buttonRef = useRef<HTMLButtonElement>(null)

  if (config.regions.length < 2) {
    return null
  }

  return <>
    <NavBarButton
      className={props.className}
      ref={buttonRef}
      icon={<Flag countryCode={currentRegionCode} size="S" />}
      onClick={toggleOpen}
      contentBreakpointMin="desktop"
    >
      {currentCurrency.toUpperCase()}
    </NavBarButton>
    <DropdownList
      size="S"
      triggerRef={buttonRef}
      anchorRef={buttonRef}
      open={open}
      onClose={closeDropdown}
    >
      <NavBarRegionItems />
    </DropdownList>
  </>
}

export default React.memo(NavBarRegionButton)
