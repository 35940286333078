import Divider from 'components/Luxkit/Divider'
import Flag from 'components/Luxkit/Flag'
import LineCheckIcon from 'components/Luxkit/Icons/line/LineCheckIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import Colour from 'components/utils/Colour'
import config from 'constants/config'
import { REGION_DROPDOWN_START_ORDER } from 'constants/config/region'
import GeoContext from 'contexts/geoContext'
import setRegionCookie from 'cookies/setRegionCookie'
import { partitionBy, sortBy } from 'lib/array/arrayUtils'
import { removeRegionCode } from 'lib/string/regionUtils'
import React, { useCallback, useContext, useMemo } from 'react'
import { useLocation } from 'react-router'

const priorityRegionCodes = new Set(REGION_DROPDOWN_START_ORDER)

interface Props {
  onRegionSelect?: React.MouseEventHandler<HTMLButtonElement>;
}

function NavBarRegionItems(props: Props) {
  const { onRegionSelect } = props
  const { pathname, search } = useLocation()
  const { currentRegionCode } = useContext(GeoContext)

  const [priorityRegions, otherRegions] = useMemo(() => {
    return partitionBy(config.regions, region => priorityRegionCodes.has(region.code))
  }, [])

  // We want our biggest markets at the top of the list, in a specific order
  const sortedRegions = useMemo(() => {
    return sortBy(priorityRegions, (region) => {
      return REGION_DROPDOWN_START_ORDER.indexOf(region.code)
    }, 'asc')
  }, [priorityRegions])

  const regionlessUrl = useMemo(() => {
    return `${removeRegionCode(pathname, currentRegionCode)}${search ?? ''}`
  }, [currentRegionCode, pathname, search])

  const onRegionClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    const code = e.currentTarget.dataset.code
    if (code) {
      setRegionCookie(code)
    }
    onRegionSelect?.(e)
  }, [onRegionSelect])

  return <>
    {sortedRegions.map(region => <ListItem
      size="medium"
      key={region.code}
      startIcon={<Flag countryCode={region.code} />}
      endIcon={currentRegionCode === region.code ? <LineCheckIcon /> : undefined}
      title={<>{region.name}, <Colour value="neutral-three">{region.currencyCode.toUpperCase()}</Colour></>}
      data-code={region.code}
      onClick={onRegionClick}
      selected={currentRegionCode === region.code}
      href={`/${region.code.toLowerCase()}${regionlessUrl}`}
    />)}
    {otherRegions.length > 0 && <Divider kind="secondary" />}
    {otherRegions.map(region => <ListItem
      size="medium"
      key={region.code}
      startIcon={<Flag countryCode={region.code} />}
      endIcon={currentRegionCode === region.code ? <LineCheckIcon /> : undefined}
      title={<>{region.name}, <Colour value="neutral-three">{region.currencyCode.toUpperCase()}</Colour></>}
      data-code={region.code}
      selected={currentRegionCode === region.code}
      onClick={onRegionClick}
      href={`/${region.code.toLowerCase()}${regionlessUrl}`}
    />)}
  </>
}

export default React.memo(NavBarRegionItems)
