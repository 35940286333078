import Label from 'components/Luxkit/Label'
import React, { ComponentProps } from 'react'
import styled from 'styled-components'

const TierLabel = styled(Label)`
  &.kind-secondary {
    &.tier-bronze {
      background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
    }
  
    &.tier-silver {
      background-color: ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.silver.normalBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
    }
  
    &.tier-gold {
      background-color: ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.gold.normalBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
    }
  
    &.tier-platinum {
      background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
    }
  }

  &.kind-primary {
    &.tier-bronze {
      background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.bronze.darkContrast};
    }
  
    &.tier-silver {
      background-color: ${props => props.theme.palette.product.luxLoyalty.silver.darkBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.silver.darkContrast};
    }
  
    &.tier-gold {
      background-color: ${props => props.theme.palette.product.luxLoyalty.gold.darkBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.gold.darkContrast};
    }
  
    &.tier-platinum {
      background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.darkBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.platinum.darkContrast};
    }
  }

  &.kind-tertiary {
    &.tier-bronze {
      background-color: ${props => props.theme.palette.product.luxLoyalty.bronze.lightBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.bronze.lightBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.bronze.normalContrast};
    }
  
    &.tier-silver {
      background-color: ${props => props.theme.palette.product.luxLoyalty.silver.lightBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.silver.lightBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.silver.normalContrast};
    }
  
    &.tier-gold {
      background-color: ${props => props.theme.palette.product.luxLoyalty.gold.lightBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.gold.lightBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.gold.normalContrast};
    }
  
    &.tier-platinum {
      background-color: ${props => props.theme.palette.product.luxLoyalty.platinum.lightBackground};
      border-color: ${props => props.theme.palette.product.luxLoyalty.platinum.lightBackground};
      color: ${props => props.theme.palette.product.luxLoyalty.platinum.normalContrast};
    }
  }
`

type InheritedLabelProps = Pick<ComponentProps<typeof Label>, 'children' | 'startIcon' | 'format'>

interface Props extends InheritedLabelProps {
  tier: App.LuxLoyaltyTier;
  kind: 'primary' | 'secondary' | 'tertiary';
}

function LuxLoyaltyTierLabel({ tier, kind, ...rest }: Props) {
  return <TierLabel
    {...rest}
    className={`tier-${tier}`}
    kind={kind}
    variant="grey"
  />
}

export default LuxLoyaltyTierLabel
