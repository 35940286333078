import React, { SVGAttributes } from 'react'

interface Props extends SVGAttributes<SVGSVGElement> {}

function LuxLoyaltyProgramNameLogo(props: Props) {
  return <svg {...props} fill="none" viewBox="0 0 62 14">
    <path d="M61.6004 12.3258V13.2384H54.5416V3.25637H61.3865V4.16901H55.5969V7.71977H60.759V8.61816H55.5969V12.3258H61.6004ZM59.005 0.675293H60.3597L58.0496 2.44354H57.0514L59.005 0.675293Z" fill="currentColor"/>
    <path d="M48.4352 4.169H44.9272V3.25636H52.9984V4.169H49.4904V13.2384H48.4352V4.169Z" fill="currentColor"/>
    <path d="M43.7301 12.3258V13.2384H36.6713V3.25637H43.5162V4.16901H37.7266V7.71977H42.8887V8.61816H37.7266V12.3258H43.7301ZM41.1347 0.675293H42.4895L40.1793 2.44354H39.1811L41.1347 0.675293Z" fill="currentColor"/>
    <path d="M32.7961 3.25636H33.8513V13.2384H32.7961V3.25636Z" fill="currentColor"/>
    <path d="M26.5965 13.324C25.6078 13.324 24.7141 13.1053 23.9156 12.668C23.1265 12.2307 22.5038 11.627 22.0475 10.857C21.6007 10.0774 21.3773 9.20755 21.3773 8.24737C21.3773 7.28719 21.6007 6.42208 22.0475 5.65203C22.5038 4.87248 23.1313 4.26405 23.9298 3.82674C24.7284 3.38943 25.622 3.17078 26.6107 3.17078C27.3523 3.17078 28.0367 3.29437 28.6642 3.54154C29.2916 3.78872 29.824 4.14997 30.2613 4.62531L29.5911 5.29553C28.8115 4.50647 27.8276 4.11194 26.6392 4.11194C25.8502 4.11194 25.1324 4.29257 24.486 4.65383C23.8395 5.01508 23.3309 5.50943 22.9601 6.13688C22.5989 6.76432 22.4183 7.46782 22.4183 8.24737C22.4183 9.02692 22.5989 9.73042 22.9601 10.3579C23.3309 10.9853 23.8395 11.4797 24.486 11.8409C25.1324 12.2022 25.8502 12.3828 26.6392 12.3828C27.8371 12.3828 28.821 11.9835 29.5911 11.1849L30.2613 11.8552C29.824 12.3305 29.2869 12.6965 28.6499 12.9532C28.0225 13.2004 27.338 13.324 26.5965 13.324Z" fill="currentColor"/>
    <path d="M14.3775 13.324C13.3888 13.324 12.4904 13.1053 11.6824 12.668C10.8838 12.2212 10.2564 11.6127 9.80005 10.8427C9.35323 10.0727 9.12982 9.20755 9.12982 8.24737C9.12982 7.28719 9.35323 6.42208 9.80005 5.65203C10.2564 4.88199 10.8838 4.27831 11.6824 3.841C12.4904 3.39419 13.3888 3.17078 14.3775 3.17078C15.3662 3.17078 16.2551 3.38943 17.0442 3.82674C17.8427 4.26405 18.4702 4.87248 18.9265 5.65203C19.3828 6.42208 19.611 7.28719 19.611 8.24737C19.611 9.20755 19.3828 10.0774 18.9265 10.857C18.4702 11.627 17.8427 12.2307 17.0442 12.668C16.2551 13.1053 15.3662 13.324 14.3775 13.324ZM14.3775 12.3828C15.1666 12.3828 15.8796 12.2069 16.5165 11.8552C17.1535 11.4939 17.6526 10.9996 18.0139 10.3721C18.3751 9.73517 18.5557 9.02692 18.5557 8.24737C18.5557 7.46782 18.3751 6.76432 18.0139 6.13688C17.6526 5.49993 17.1535 5.00558 16.5165 4.65383C15.8796 4.29257 15.1666 4.11194 14.3775 4.11194C13.5885 4.11194 12.8707 4.29257 12.2243 4.65383C11.5873 5.00558 11.0835 5.49993 10.7127 6.13688C10.3514 6.76432 10.1708 7.46782 10.1708 8.24737C10.1708 9.02692 10.3514 9.73517 10.7127 10.3721C11.0835 10.9996 11.5873 11.4939 12.2243 11.8552C12.8707 12.2069 13.5885 12.3828 14.3775 12.3828Z" fill="currentColor"/>
    <path d="M3.70762 13.324C2.9661 13.324 2.25309 13.2051 1.56861 12.9675C0.893632 12.7298 0.370762 12.4161 0 12.0263L0.413543 11.2135C0.774798 11.5747 1.25489 11.8694 1.85381 12.0976C2.45273 12.3162 3.07067 12.4256 3.70762 12.4256C4.60125 12.4256 5.27148 12.264 5.71829 11.9407C6.16511 11.608 6.38852 11.1802 6.38852 10.6573C6.38852 10.258 6.26493 9.93957 6.01776 9.7019C5.78009 9.46423 5.48538 9.2836 5.13363 9.16002C4.78188 9.02692 4.29229 8.88432 3.66484 8.73221C2.91381 8.54208 2.31489 8.36145 1.86807 8.19033C1.42126 8.0097 1.03623 7.73876 0.713004 7.3775C0.399282 7.01625 0.242421 6.52665 0.242421 5.90872C0.242421 5.40486 0.375516 4.94854 0.641704 4.53975C0.907892 4.12145 1.31668 3.78872 1.86807 3.54154C2.41946 3.29437 3.10395 3.17078 3.92152 3.17078C4.49193 3.17078 5.04807 3.25159 5.58995 3.4132C6.14134 3.56531 6.61668 3.77921 7.01596 4.0549L6.65946 4.89625C6.24116 4.62055 5.79435 4.41616 5.31901 4.28307C4.84368 4.14047 4.37785 4.06916 3.92152 4.06916C3.0469 4.06916 2.38619 4.24029 1.93937 4.58253C1.50206 4.91526 1.28341 5.34782 1.28341 5.8802C1.28341 6.27948 1.40224 6.60271 1.63991 6.84988C1.88708 7.08755 2.1913 7.27293 2.55256 7.40602C2.92332 7.52961 3.41767 7.66746 4.0356 7.81957C4.76762 8.00019 5.35704 8.18082 5.80385 8.36145C6.26018 8.53257 6.6452 8.79876 6.95892 9.16002C7.27264 9.51176 7.4295 9.99185 7.4295 10.6003C7.4295 11.1041 7.29166 11.5652 7.01596 11.9835C6.74977 12.3923 6.33623 12.7203 5.77533 12.9675C5.21444 13.2051 4.5252 13.324 3.70762 13.324Z" fill="currentColor"/>
  </svg>
}

export default LuxLoyaltyProgramNameLogo
