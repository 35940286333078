import LayoutContainer from 'components/Common/LayoutContainer'
import Pane from 'components/Common/Pane'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { rem } from 'polished'
import React, { useContext, useMemo } from 'react'
import { styled } from 'styled-components'
import NavBarContactsButton from './NavBarButtons/NavBarContactsButton'
import Group from 'components/utils/Group'
import GeoContext from 'contexts/geoContext'
import { ATOL_REGIONS } from 'constants/config/region'
import BodyText from 'components/Luxkit/Typography/BodyText'
import NavBarUSP, { NavUspType } from './NavBar/NavBarUSP'
import { useAppSelector } from 'hooks/reduxHooks'
import { selectLoggedIn } from 'selectors/accountSelectors'
import NavBarChatButton from './NavBarButtons/NavBarChatButton'
import { isSupportAssistantEnabled } from 'selectors/featuresSelectors'

const BarPane = styled(Pane)`
  width: 100%;
`

const TertiaryBar = styled(LayoutContainer)`
  display: flex;
  justify-content: center;
  padding: 0 ${rem(16)};

  ${mediaQueryUp.desktop} {
    justify-content: space-between;
    padding: 0 ${rem(20)};
  }
`

const USPSection = styled(Group)`
  display: none;

  ${mediaQueryUp.desktop} {
    display: flex;
  }
`

interface Props {
  className?: string;
}

function NavBarTertiaryBar(props: Props) {
  const { className } = props
  const { currentRegionCode } = useContext(GeoContext)

  const chatEnabled = useAppSelector(isSupportAssistantEnabled)
  const loggedIn = useAppSelector(selectLoggedIn)
  const usps = useMemo<Array<NavUspType>>(() => {
    const usps: Array<NavUspType> = loggedIn ? [] : ['google', 'trustpilot']
    if (ATOL_REGIONS.includes(currentRegionCode)) {
      usps.unshift('atol')
    }
    return usps
  }, [currentRegionCode, loggedIn])

  return <BarPane type="light-grey" className={className}>
    <TertiaryBar variant="extraLarge">
      <USPSection direction="horizontal" verticalAlign="center" gap={8}>
        {usps.map((usp, index) => <React.Fragment key={usp}>
          {index !== 0 && <BodyText variant="small">·</BodyText>}
          <NavBarUSP type={usp} />
        </React.Fragment>)}
      </USPSection>
      <Group direction="horizontal" desktopGap={8}>
        <NavBarContactsButton textBreakpoint={usps.length > 2 ? 'desktop' : undefined} />
        {chatEnabled && <NavBarChatButton />}
      </Group>
    </TertiaryBar>
  </BarPane>
}

export default NavBarTertiaryBar
