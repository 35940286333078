import React, { useCallback, useMemo, useState } from 'react'

import Flag from 'components/Luxkit/Flag'
import { connect } from 'react-redux'
import setRegionCookie from 'cookies/setRegionCookie'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import SlideDown from 'components/utils/SlideDown'
import ListItem from 'components/Luxkit/List/ListItem'
import config from 'constants/config'
import { sortBy } from 'lib/array/arrayUtils'
import { REGION_DROPDOWN_START_ORDER } from 'constants/config/region'

interface Props {
  location: any;
  currentRegionCode: string;
  className?: string;
}

function NavigationRegionMenu(props: Props) {
  const {
    currentRegionCode,
    location,
    className,
  } = props

  // We want our biggest markets at the top of the list, in a specific order
  const sortedRegions = useMemo(() => {
    return sortBy(config.regions, (region) => {
      const regionOrder = REGION_DROPDOWN_START_ORDER.indexOf(region.code)
      if (regionOrder === -1) {
        // Put it at the bottom of the list if it's not in the desired order
        return 999
      }
      return regionOrder
    }, 'asc')
  }, [])

  const [isOpen, setOpen] = useState(false)

  const onMenuToggle = useCallback((e: React.MouseEvent<HTMLElement>) => {
    if (!isOpen) {
      e.currentTarget.scrollIntoView()
    }

    setOpen(!isOpen)
  }, [isOpen])

  if (config.regions.length < 2) {
    return null
  }

  const onRegionClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    const regionCode = e.currentTarget.dataset.regioncode

    if (regionCode && regionCode !== currentRegionCode) {
      setRegionCookie(regionCode)
      window.location.href = location.pathname.replace(/^\/[a-z]+/, '/' + regionCode.toLowerCase()) + location.search
    }
  }

  const regionData = config.regions.find(region => (region.code === currentRegionCode))
  const selectedRegionFlagToDisplay = currentRegionCode === 'NL' ? 'EU' : currentRegionCode

  return (<div
    className={className}
    role="presentation"
    aria-label="Select your region"
  >
    <ListItem
      title={regionData?.currencyCode ?? currentRegionCode}
      startIcon={<Flag size="M" countryCode={selectedRegionFlagToDisplay} />}
      endIcon={<DropdownChevron open={isOpen} />}
      aria-expanded={isOpen}
      onClick={onMenuToggle}
    />
    <SlideDown show={isOpen}>
      {sortedRegions.map(region => (
        <ListItem
          title={region.name}
          startIcon={<Flag countryCode={region.code} />}
          href={`/${region.code.toLowerCase()}`}
          onClick={onRegionClick}
          data-regioncode={region.code}
          key={region.code}
        />
      ))}
    </SlideDown>
  </div>
  )
}

function mapStateToProps(state: App.State) {
  return {
    location: state.router.location,
    currentRegionCode: state.geo.currentRegionCode,
  }
}

export default connect(mapStateToProps)(NavigationRegionMenu)
