import React, { useCallback } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import { rem } from 'polished'
import ListItem from 'components/Luxkit/List/ListItem'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import { getNavigationMenuIcon } from 'content/components/Icon/utils'

const NewBadge = styled.span`
  margin-left: ${rem(8)};
  background-color: ${props => props.theme.palette.highlight.secondary.lightBackground};
  color: ${props => props.theme.palette.highlight.secondary.normalForeground};
  padding: 0 ${rem(2)};
  border-radius: ${props => props.theme.borderRadius.S};
  font-weight: ${props => props.theme.font.primary.weight.bold};
  /* This has not been designed to match luxkit yet, and needs specific values */
  font-size: 10px;
  line-height: 13px;
  vertical-align: text-top;

`

interface Props extends Omit<React.HTMLAttributes<HTMLElement>, 'onClick'> {
  navItem: App.NavItem;
  onClick?: (navItem: App.NavItem) => void;
  isOpen?: boolean;
}

function NavigationMenuItem(props: Props) {
  const { className, navItem, isOpen, onClick, ...rest } = props
  const iconName = navItem.sideNavIconName || navItem.iconName
  const Icon = iconName && getNavigationMenuIcon(iconName)
  const onNavClick = useCallback(() => {
    onClick?.(navItem)
  }, [navItem, onClick])
  return (
    <ListItem
      {...rest}
      title={<div>
        {navItem.text}{navItem.newLabel && <NewBadge>NEW</NewBadge>}
      </div>}
      role="menuitem"
      startIcon={Icon && <Icon size="M" />}
      className={cn(className, navItem.linkClass)}
      to={navItem.to}
      href={navItem.href}
      target={navItem.target}
      onClick={onNavClick}
      endIcon={navItem.items?.length ? <DropdownChevron open={isOpen} /> : <LineAngleRightIcon />}
    />
  )
}

export default NavigationMenuItem
