import cn from 'clsx'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import TripGuard from 'tripPlanner/components/TripGuard/TripGuard'
import NavBarButton from '../NavBarButton/NavBarButton'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import loadable from '@loadable/component'
import { useTrips } from 'tripPlanner/hooks/api/trip'
import { useUserSettings } from 'tripPlanner/hooks/api/userSettings'
import AccountAccessModal, { AccountAccessModalResult } from 'components/Account/AccountAccess/AccountAccessModal'
import ModalContext from 'contexts/ModalContext'
import { tripLoginModalSignUpDismiss, tripLoginModalSignUpView } from 'analytics/eventDefinitions'
import { fireInteractionEvent } from 'api/googleTagManager'
import Badge from 'components/Luxkit/Badge'
import { useInView } from 'react-intersection-observer'
import { clearFirstTripItemHasJustBeenAdded } from 'tripPlanner/reducers/actions'
import FirstTripItemOnboardingTooltip from 'tripPlanner/components/Drawer/FirstTripItemOnboardingTooltip'
import { mediaQueryUp } from 'components/utils/breakpoint'
import styled from 'styled-components'

const TripNavButton = styled(NavBarButton)`
  &:not(.logged-in) {
    display: none;

    ${mediaQueryUp.tablet} {
      display: flex;
    }
  }
`

const SavedViewModal = loadable(() => import(/* webpackChunkName: "TripSavedViewModal" */ 'tripPlanner/components/Drawer/SavedViewModal'))

interface Props {
  className?: string;
}

function NavBarTripButton(props: Props) {
  const { className } = props
  const navRef = useRef<HTMLButtonElement>(null)
  const dispatch = useAppDispatch()
  const { data: trips = [] } = useTrips()
  const { data: userSettings } = useUserSettings()
  const userHasBeenAddedToNewTrips = !!userSettings?.recentlyAddedToTripIDs?.length
  const showModal = useContext(ModalContext)

  const loggedIn = useAppSelector(selectLoggedIn)
  const tripItemHasJustBeenAdded = useAppSelector(state => state.tripPlanner.tripItemHasJustBeenAdded)
  const firstItemJustBeenAdded = useAppSelector(state => state.tripPlanner.firstTripItemHasJustBeenAdded)

  const onNavClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async() => {
    if (!loggedIn) {
      const result = await showModal<AccountAccessModalResult>(<AccountAccessModal initialMode="tripPlannerLogin" />)
      if (!result.loggedIn) {
        fireInteractionEvent(tripLoginModalSignUpDismiss('saved_drawer'))
        return
      }
    }
    fireInteractionEvent(tripLoginModalSignUpView('saved_drawer'))
    showModal(<SavedViewModal mode="drawer-starboard" size="L" />)
  }, [loggedIn, showModal])

  const [menuItemRef, menuItemInView] = useInView({ triggerOnce: true, skip: !firstItemJustBeenAdded })
  const showTripNotificationDot = userHasBeenAddedToNewTrips || tripItemHasJustBeenAdded
  const showTooltip = menuItemInView && firstItemJustBeenAdded

  const onDismissFirstTripTooltip = useCallback(() => {
    dispatch(clearFirstTripItemHasJustBeenAdded())
  }, [dispatch])

  useEffect(() => {
    menuItemRef(navRef.current)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navRef.current])

  return <TripGuard>
    <TripNavButton
      className={cn(className, { 'logged-in': loggedIn })}
      icon={<LineHeartIcon />}
      contentBreakpointMin="desktop"
      onClick={onNavClick}
      ref={navRef}
      badge={showTripNotificationDot ? <Badge kind="dot" mode="critical" /> : undefined}
    >
      Trip Plans
    </TripNavButton>
    {showTooltip && trips[0] && (
      <FirstTripItemOnboardingTooltip
        isOpen
        anchorRef={navRef}
        placement="bottom"
        trip={trips[0]}
        onDismiss={onDismissFirstTripTooltip}
      />)}
  </TripGuard>
}

export default React.memo(NavBarTripButton)
