import { authLogout } from 'actions/AuthActions'
import { pushWithRegion } from 'actions/NavigationActions'
import LineSignoutIcon from 'components/Luxkit/Icons/line/LineSignoutIcon'
import { useAppDispatch } from 'hooks/reduxHooks'
import React, { ComponentProps, MouseEventHandler, useCallback } from 'react'
import NavBarListItem from 'components/NavKit/NavBarListItem'
import NavBarListItemIcon from 'components/NavKit/NavBarListItemIcon'

type InheritedListItemProps = Pick<ComponentProps<typeof NavBarListItem>, 'onClick'>

function AccountMenuLogoutListItem({ onClick }: InheritedListItemProps) {
  const dispatch = useAppDispatch()
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    dispatch(authLogout())
    dispatch(pushWithRegion('/'))
    onClick?.(event)
  }, [onClick, dispatch])

  return <NavBarListItem
    startIcon={<NavBarListItemIcon Icon={LineSignoutIcon} />}
    title="Log out"
    onClick={handleClick}
  />
}

export default AccountMenuLogoutListItem
